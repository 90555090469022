import { GroupBase, Props, StylesConfig } from 'react-select';

import { HideScroll } from 'shared/styles/hide-scroll';
import { SelectOption } from './types';
import done from 'modules/ud-ui/components/icon/icons/done.svg';
import styled from '@emotion/styled';

export const Option = styled.div`
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.SurfaceCultured10};
  }
`;

export const OptionCheckbox = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 4px;

  :after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
  }

  &[data-checked='true'] {
    background-color: ${({ theme }) => theme.colors.AccentNapplesYellow};
    &:after {
      background-image: url(${done});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px;
    }
  }
  &[data-checked='false'] {
    background-color: ${({ theme }) => theme.colors.SurfacePlatinum20};
  }
`;

export const MultiValue = styled.div`
  display: flex;
  &:not(:first-child):before {
    content: ' ∙ ';
    display: block;
    color: ${({ theme }) => theme.colors.AccentNapplesYellow};
  }
`;

export const ValueContainer = styled.div`
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 50px;
  margin-bottom: -50px;

  ${HideScroll}
`;

export const Control = styled.div`
  background-color: ${({ theme }) => theme.colors.SurfaceWhite};
  border-radius: 8px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.SurfaceWhite};
  cursor: pointer;
  &.focused {
    border-color: ${({ theme }) => theme.colors.AccentNapplesYellow};
  }
  &.disabled {
    opacity: 0.5;
    pointer-events:none;
  }
  &.opened {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const SelectContainer = styled.div`
  position: relative;
  box-sizing: border-box;
`;

export const MenuList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
`;

export const MenuInfo = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.BorderGray50};
`;

export function getSelectStyles<
  isMulti extends boolean,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
>(): StylesConfig<SelectOption, isMulti, Group> {
  return {
    menu: ({ boxShadow, marginTop, ...otherBase }) => ({
      ...otherBase,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    }),
  };
}

export const plainStyles: Props<SelectOption>['styles'] = {
  menu: ({ boxShadow, marginTop, ...otherBase }) => ({
    ...otherBase,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }),
};
