import { AuthRequestAuthCodePayload } from 'modules/auth/domain/interfaces/AuthRequestAuthCodePayload';
import { Formik } from 'formik';
import React from 'react';
import { SchemaOf } from 'yup';
import UDButton from 'modules/ud-ui/components/button';
import UDInputPhone from 'modules/ud-form/components/phone-input';
import { useTranslation } from 'react-i18next';

type RequestAuthCodeFormProps = {
  initialValues: {
    phone: string;
  };
  isLoading: boolean;
  onSubmit: (values: AuthRequestAuthCodePayload) => void;
  validationSchema?: SchemaOf<AuthRequestAuthCodePayload>;
};

const RequestAuthCodeForm = (props: RequestAuthCodeFormProps) => {
  const { t } = useTranslation();

  return (
    <Formik<AuthRequestAuthCodePayload>
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={props.validationSchema}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <UDInputPhone
            name='phone'
            label={t('signIn.phoneInputLabel')}
            placeholder={t('signIn.phoneInputPlaceholder')}
            className='mb-5'
            value={formProps.values.phone}
          />
          <UDButton
            variant="primary"
            type="submit"
            className="w-100 mb-3"
            style={{ fontFamily: 'SF Pro Display', fontWeight: 300 }}
            loading={props.isLoading}
            disabled={props.isLoading || !formProps.isValid || !formProps.dirty}
          >
            {t('signIn.requestCodeButtonLabel')}
          </UDButton>
        </form>
      )}
    </Formik>
  );
}

export default RequestAuthCodeForm;
