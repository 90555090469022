import * as yup from 'yup';

import { AuthSignUpPayload } from '../interfaces/AuthSignUpPayload';
import { TFunction } from 'i18next';
import { validPhoneRegex } from 'modules/ud-form/components/phone-input/validators/phone';

export const authSignUpValidator = (t: TFunction): yup.SchemaOf<AuthSignUpPayload> => yup
  .object()
  .shape({
    firstName: yup.string().required(t('signUp.validationError.firstNameRequired')),
    lastName: yup.string().required(t('signUp.validationError.lastNameRequired')),
    middleName: yup.string().optional(),
    phone: yup.string()
      .required(t('signUp.validationError.phoneRequired'))
      .matches(validPhoneRegex, t('signUp.validationError.phoneInvalid')),
  });
