import React, { useCallback, useMemo, useState } from 'react';
import { authRequestAuthCode, authSignIn } from 'modules/auth/store/actions';
import {
  authStoreCheckingSelector,
  authStoreCodeRequestedSelector,
  authStoreCodeRequestingSelector,
  authStoreLoadingSelector
} from 'modules/auth/store/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../../../store/store';
import AuthLayoutsMain from '../../layouts';
import { AuthRequestAuthCodePayload } from 'modules/auth/domain/interfaces/AuthRequestAuthCodePayload';
import { AuthSignInPayload } from 'modules/auth/domain/interfaces/AuthSignInPayload';
import RequestAuthCodeForm from '../../components/requestAuthCodeForm';
import SignInForm from '../../components/signInForm';
import { authRequestCodeValidator } from 'modules/auth/domain/validators/auth-request-code.validator';
import { authSignInValidator } from 'modules/auth/domain/validators/auth-sign-in.validator';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const AuthPagesSignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const isAuthCodeRequested = useSelector(authStoreCodeRequestedSelector);
  const isAuthCodeRequesting = useSelector(authStoreCodeRequestingSelector);
  const isLoading = useSelector(authStoreLoadingSelector);
  const isAuthChecking = useSelector(authStoreCheckingSelector);
  const to = (useLocation().state as ({ to: string } | null))?.to;

  const [username, setUsername] = useState('');

  const onCodeRequest = useCallback((data: AuthRequestAuthCodePayload) => {
    setUsername(data.phone);
    dispatch(authRequestAuthCode(data));
  }, [dispatch]);

  const onSignIn = useCallback((data: AuthSignInPayload) => {
    dispatch(authSignIn({ ...data, to }));
  }, [dispatch, to]);

  const isAuthInProcess = useMemo(() => {
    return isAuthCodeRequested || isAuthChecking;
  }, [isAuthCodeRequested, isAuthChecking]);

  const isSignInLoading = useMemo(() => {
    return isLoading || isAuthChecking;
  }, [isLoading, isAuthChecking]);

  return (
    <AuthLayoutsMain
      title={t('signIn.title')}
      subtitle={t('signIn.subtitle')}
      action={{
        title: t('signIn.signUpHint'),
        linkLabel: t('signIn.signUpButtonLabel'),
        link: '/sign-up',
      }}>
      {isAuthInProcess
        ? <SignInForm
          initialValues={{ username, password: '' }}
          onSubmit={onSignIn}
          isLoading={isSignInLoading}
          validationSchema={authSignInValidator(t)}
        />
        : <RequestAuthCodeForm
          initialValues={{ phone: '' }}
          onSubmit={onCodeRequest}
          isLoading={isAuthCodeRequesting}
          validationSchema={authRequestCodeValidator(t)}
        />
      }
    </AuthLayoutsMain>
  );
};

export default AuthPagesSignIn;
