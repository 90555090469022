import UDInput from "modules/ud-form/components/input/component";
import { scrollbar } from "shared/styles/scrollbar";
import styled from "@emotion/styled";

export const Container = styled.div`
    width: 100%;
    height: 40px;
    border-radius: 8px;

    background-color: white;
    padding: 8px 6px;

    display: flex;
    align-items: center;
    justify-content: start;

    gap: 8px;
`;

export const Prefix = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100px;
    height: 26px;
    border-radius: 6px;

    background: #9093AA26;
    user-select: none;
`;

export const Dropdown = styled.div<{ isOpen: boolean, top: number, left: number, width: number }>`
    position: absolute;
    top: ${({ top }) => top}px;
    left: ${({ left }) => left}px;
    width: ${({ width }) => width}px;
    height: min-content;
    max-height: 400px;
    overflow-y: auto;
    opacity: ${({ isOpen }) => isOpen ? 1 : 0};
    visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
    transform: ${({ isOpen }) => isOpen ? 'translateY(0)' : 'translateY(-10px)'};
    transition: opacity 0.2s ease-in-out,
                visibility 0.2s ease-in-out,
                transform 0.2s ease-in-out;
    flex-direction: column;
    gap: 8px;

    ${({ theme }) => scrollbar(theme)}
    background-color: white;
    border-radius: 8px;
    padding-block: 16px;
    z-index: 1000;
`;

export const DropdownItem = styled.div<{ isActive: boolean }>`
    padding-inline: 12px;
    height: 40px;
    background-color: ${({ isActive }) => isActive ? '#9093AA26' : 'transparent'};
    transition: background-color 0.2s ease-in-out;

    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: ${({ isActive }) => isActive ? '#9093AA26' : 'rgba(46, 47, 54, 0.15)'};

    }

    .icon {
        margin-right: 10px;
    }

    .prefix {
        margin-left: auto;
    }
`;

export const Input = styled(UDInput)`
    padding: 0;

    &:hover,
    &:focus {
        outline: none;
        border: 1px solid transparent;
    }
`;
