import { Container, DropdownItem, Input, Prefix } from './styles';
import { FieldProps, useFormikContext } from 'formik';
import { ReactElement, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ValidPhoneCode, formatPhone } from '../../../auth/ui/formatters/formatPhone';

import { ArrowDownIcon } from '../../../ud-ui/components/icon/arrow-down-icon';
import { DivProps } from 'typings';
import { Dropdown } from './dropdown';
import { UDFormComponentProps } from 'modules/ud-form/components/types';
import UDFormFieldWrapper from 'modules/ud-form/wrapper';
import UDText from 'modules/ud-ui/components/text';
import classNames from 'classnames';
import { parsePhone } from '../../../auth/ui/formatters/parsePhone';
import { phonePrefixes } from 'modules/ud-form/components/phone-input/domain/constants/phone-prefixes';

type UDFormPhonePrefixProps = UDFormComponentProps & PhonePrefixProps & {
    selectComponent?: (props: any) => ReactElement<any, any> | null;
    placeholder?: string;
};

type Returning = (
    props: UDFormPhonePrefixProps,
) => JSX.Element;

type PhonePrefixProps = Omit<DivProps, 'onChange' | 'children'> & {
    value?: string;
    onChange?: (value: string) => void;
}

const ContainerWithRef = forwardRef<HTMLDivElement, DivProps>((props, ref) => (
    <Container {...props} ref={ref} />
));

const UDInputPhone: Returning = (props: UDFormPhonePrefixProps) => {
    const {
        value,
        name,
        containerProps,
        required,
        label,
        labelProps,
        selectComponent,
        placeholder,
        ...rest
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const parentRef = useRef<HTMLDivElement>(null);

    const initialPrefix = useMemo(() => {
        const prefix = phonePrefixes.find(p => value?.startsWith(p.prefix));
        return prefix || phonePrefixes[0];
    }, [value]);

    const [activeOption, setActiveOption] = useState(initialPrefix);

    const { setFieldValue } = useFormikContext();

    const onChange = useCallback(
        (inputValue: string) => {
            const formattedValue = inputValue.replace(/\D/g, '');
            const fullNumber = (`${activeOption.prefix}${formattedValue}`);

            setFieldValue(name, fullNumber);

            if (props.onChange) {
                props.onChange(fullNumber);
            }
        },
        [activeOption.prefix, name, props, setFieldValue],
    );

    const selectOption = useCallback((option: typeof phonePrefixes[0]) => {
        setActiveOption(option);
        setIsOpen(false);

        const currentNumber = value?.replace(activeOption.prefix, '').replace(/\D/g, '');
        const newValue = (`${option.prefix}${currentNumber}`);

        setFieldValue(name, newValue);
        if (props.onChange) {
            props.onChange(newValue);
        }
    }, [value, activeOption.prefix, name, setFieldValue, props]);

    const options = useMemo(() => phonePrefixes.map((option) => (
        <DropdownItem key={option.id} isActive={activeOption.id === option.id} onClick={() => selectOption(option)}>
            <option.icon className='icon' />
            <UDText type='subhead' className='country'>{option.name}</UDText>
            <UDText type='subhead' className='prefix'>{option.prefix}</UDText>
        </DropdownItem>
    )), [activeOption, selectOption]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (parentRef.current && !parentRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <UDFormFieldWrapper
            name={name}
            containerProps={{ ...containerProps, className: classNames('flex-column', containerProps?.className) }}
            label={label}
            labelProps={labelProps}
            required={required}>
            {({ field }: FieldProps<string>) => {
                const numberPart = field.value.replace(activeOption.prefix.replace(/\D/g, ''), '')

                return (
                    <ContainerWithRef {...(rest as DivProps)} ref={parentRef}>
                        <Prefix onClick={() => setIsOpen(!isOpen)}>
                            <activeOption.icon />
                            <UDText type='subhead'>{activeOption.prefix}</UDText>
                            <ArrowDownIcon direction={isOpen ? 'down' : 'up'} />
                        </Prefix>
                        <Input
                            value={numberPart}
                            placeholder={placeholder}
                            wrapperProps={{ className: 'w-100' }}
                            formatter={(value) => formatPhone(value, activeOption.prefix as ValidPhoneCode)}
                            parser={parsePhone}
                            onChange={(event) => onChange(event.target.value)}
                        />
                        <Dropdown parentRef={parentRef} isOpen={isOpen} options={options} />
                    </ContainerWithRef>
                );
            }}
        </UDFormFieldWrapper>
    );
};

export default UDInputPhone;
