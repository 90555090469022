import {
  DadataAreaType,
  DadataAutocomplete,
  DadataAutocompleteProps,
  DadataSelectOption,
  IDadataArea,
} from 'shared/features/dadata-address-autocomplete';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MultiValue } from 'react-select/dist/declarations/src';
import { SingleValue } from "react-select";
import UDText from 'modules/ud-ui/components/text';
import { getSelectStyles } from './styles';
import {
  isEqualDadataArea,
} from '../../../../../shared/features/dadata-address-autocomplete/infra/helper/isEqualDadataArea';
import overriddenSelectComponents from './override';
import { useTranslation } from 'react-i18next';

const LoadingMessage = () => {
  const { t } = useTranslation();

  return (
    <UDText type='subhead' className='color-SurfaceRomanSilver30'>
      {t('dadataAreaSelect.loadingMessage')}
    </UDText>
  );
};

const EmptyAddress = () => {
  const { t } = useTranslation();

  return (
    <UDText type='subhead' className='color-SurfaceRomanSilver30'>
      {t('dadataAreaSelect.emptyValue')}
    </UDText>
  );
};

const loadingMessage = () => (<LoadingMessage />);

const emptyOption = {
  label: (<EmptyAddress />),
  value: undefined,
};

const defaultOption = {
  label: 'г Новосибирск',
  value: {
    value: 'г Новосибирск',
    city: 'г Новосибирск',
    region: 'Новосибирская обл',
    country: 'Россия',
    street: undefined,
    house: undefined,
    lat: 55.028141,
    lon: 82.921117,
    type: DadataAreaType.CITY,
  },
};

export type UDDadataAreaSelectProps =
  Omit<DadataAutocompleteProps, 'hideSelectedOptions' | 'styles' | 'value' | 'onChange'>
  & {
    value?: IDadataArea;
    onChange?: (value: IDadataArea | undefined) => void;
    allowedTypes?: DadataAreaType[];
  };

export const UDDadataAreaSelect = (props: UDDadataAreaSelectProps) => {
  const {
    components,
    value,
    onChange,
    allowedTypes = [DadataAreaType.CITY],
    ...restProps
  } = props;

  const [selectedValue, selectValue] = useState<IDadataArea | undefined>(value);

  useEffect(() => {
    selectValue(value);
  }, [value]);

  const selectedOption = useMemo(() => {
    return selectedValue ? { label: selectedValue.value, value: selectedValue } : emptyOption;
  }, [selectedValue]);

  const defaultOptions = useMemo(() => {
    return selectedOption && selectedOption.value && !isEqualDadataArea(selectedOption.value, defaultOption.value)
      ? [emptyOption, selectedOption]
      : [emptyOption, defaultOption];
  }, [selectedOption]);

  const styles = getSelectStyles();
  const selectComponents = components || overriddenSelectComponents;

  const handleChange = useCallback((selected: any) => {
    const newValue = selected?.value
      ? {
        ...selected.value,
        id: value?.id,
      }
      : selected.value;

    selectValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }, [onChange, value?.id]);

  return (
    <DadataAutocomplete
      {...restProps}
      value={selectedOption}
      hideSelectedOptions={false}
      placeholder={props.placeholder || ''}
      closeMenuOnSelect={!props.isMulti}
      styles={styles}
      components={selectComponents}
      defaultOptions={defaultOptions}
      loadingMessage={loadingMessage}
      onChange={handleChange}
      allowedTypes={allowedTypes}
    />
  );
};
