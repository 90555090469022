import styled from "@emotion/styled";

export const MainBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    background-color: white;
    overflow-y: auto;
    height: 100%;

    scrollbar-gutter: stable;

    &::-webkit-scrollbar {
        width: 4px;
        background-color: #2D2D47;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.AccentNapplesYellow};
    }

    // TODO: margin-bottom: 10px, кроме последнего элемента
    & > .UDListItem {
        margin-bottom: 10px;
    }

    & > .UDListItem:last-child {
        margin-bottom: 0;
    }
`;