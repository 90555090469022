/** @jsxImportSource @emotion/react */

import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AppDispatch } from "../../../../store/store";
import LocationForm from "../components/location-form";
import { Pages } from "../../../navigation/domain/enums/pages";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import UDMainLayout from "../../../ud-ui/layout/main";
import { UDText } from "../../../ud-ui/components";
import { UpsertLocationInput } from "../../domain/interfaces/actions";
import { authStoreUserTournamentId } from "../../../auth/store/selectors";
import locationsRepository from "../../domain/repositories/locations.repository";
import { selectLocations } from "../../store/selectors";
import { upsertLocation } from "../../store/actions";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import { useQuery } from "react-query";

function LocationViewPage() {
    const dispatch = useDispatch<AppDispatch>();
    const { info } = useOrganizer();
    const tournamentId = useSelector(authStoreUserTournamentId);
    const locations = useSelector(selectLocations);

    const id = Number(useParams().id);
    const navigate = useNavigate();
    if (isNaN(id)) { navigate(Pages.LOCATIONS.INDEX) }

    // Сначала ищем локацию в сторе
    const storedLocation = locations.find(loc => loc.id === id);

    const { data: location, isLoading } = useQuery(
        ['location', id, tournamentId, storedLocation],
        () => locationsRepository.getLocation(id),
        {
            enabled: !isNaN(id) && !storedLocation && !!tournamentId,
            refetchOnWindowFocus: false,
            initialData: storedLocation
        }
    );

    const handleSubmit = useCallback(async (values: UpsertLocationInput) => {
        if (!tournamentId) {
            console.error('No tournament selected');
            return;
        }

        try {
            const locationData: UpsertLocationInput = {
                ...values,
                tournamentId,
            };

            await dispatch(upsertLocation(locationData)).unwrap();
            navigate(Pages.LOCATIONS.INDEX);
        } catch (error) {
            console.error('Failed to save location:', error);
        }
    }, [dispatch, navigate, tournamentId]);

    const handleCancel = useCallback(() => {
        navigate(Pages.LOCATIONS.INDEX);
    }, [navigate]);

    const breadcrumbs = useMemo(() => {
        return [
            { label: 'Локации', to: Pages.LOCATIONS.INDEX },
            { label: location?.name ?? 'Неизвестная локация', to: Pages.LOCATIONS.builders.edit(id) },
        ];
    }, [id, location?.name]);

    const breadcrumb = useMemo(
        () => (
            <UDBreadcrumbs
                className='my-10'
                breadcrumbs={breadcrumbs}
            />
        ),
        [breadcrumbs],
    );

    if (!info || isLoading) {
        return (
            <UDMainLayout headerContent={breadcrumb}>
                <div className='container pt-6'>
                    <div className='skeleton-block' style={{ height: '200px' }} />
                </div>
            </UDMainLayout>
        );
    }

    if (!location) {
        return (
            <UDMainLayout headerContent={breadcrumb}>
                <div className='container pt-6'>
                    <div className='flex-column flex-center mt-24'>
                        <div className='text body bold mb-2'>
                            Такая локация не найдена
                        </div>
                    </div>
                </div>
            </UDMainLayout>
        );
    }

    return (
        <UDMainLayout headerContent={breadcrumb}>
            <div className='container py-6'>
                <UDText type="headline" className="mr-10">
                    Локация для проведения турнира
                </UDText>
            </div>
            <div className="container d-flex align-items-center bb-1" />
            <div className='container pt-6'>
                <LocationForm
                    location={location}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                />
            </div>
        </UDMainLayout>
    );
}

export default LocationViewPage;