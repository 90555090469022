import React, { useCallback, useMemo } from "react";

import { AppDispatch } from "../../../../store/store";
import PerPage from "../../../ud-ui/components/per-page/per-page";
import UDInput from "../../../ud-form/components/input/component";
import classNames from "classnames";
import debounce from "../../../utils/debounce";
import { setLocationsPerPage } from "../../store";
import { useDispatch } from "react-redux";
import useLocations from "../hooks/useLocations";

type Props = {
  className?: string;
  onFilter: (changes: { search: string }) => void;
  onPerPageChange: (value: number) => void;
};

function LocationFilter(props: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const { onFilter, className, onPerPageChange } = props;
  const { filter, locationsPerPage } = useLocations();

  const handleChangeSearch = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        onFilter({
          search: e.target.value,
        });
      }, 300),
    [onFilter],
  );

  const handleChangeResultsOnPage = useCallback((value: number) => {
    onPerPageChange(value);
  }, [onPerPageChange]);

  return (
    <div className={classNames('container py-6', className)}>
      <div className='row gx-2'>
        <div className='col-9'>
          <div className='row gx-2'>
            <div className='col-8'>
              <UDInput
                defaultValue={filter.text}
                placeholder='Поиск по локациям'
                onChange={handleChangeSearch}
                iconProps={{
                  position: 'icon-left',
                  name: 'search',
                  size: 20,
                  componentProps: { className: 'color-SurfaceRomanSilver30' },
                }}
              />
            </div>
          </div>
        </div>
        <PerPage value={locationsPerPage} onChange={handleChangeResultsOnPage} />
      </div>
    </div>
  );
}

export default LocationFilter;