import { FieldProps, useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import UDSelect, { UDSelectProps } from './component';

import { Location } from '../../../locations/domain/interfaces/location';
import { SelectOption } from './component/types';
import { UDFormComponentProps } from '../types';
import UDFormFieldWrapper from '../../wrapper';
import { useLocation } from '../../../locations/ui/hooks/useLocation';

type UDFormLocationSelectProps<isMulti extends boolean> = UDFormComponentProps &
    Omit<UDSelectProps<isMulti>, 'options'> & {
        selectComponent?: (props: any) => React.ReactElement<any, any> | null;
        formatOptionLabel?: (location: Location) => string;
        filterLocations?: (locations: Location[]) => Location[];
        availableLocations?: Location[];
    };

type Returning = <isMulti extends boolean = false>(
    props: UDFormLocationSelectProps<isMulti>,
) => JSX.Element;

const defaultFormatOptionLabel = (location: Location) => location.name;

const UDFormLocationSelect: Returning = (props) => {
    const {
        name,
        containerProps,
        required,
        label,
        labelProps,
        selectComponent,
        formatOptionLabel = defaultFormatOptionLabel,
        filterLocations,
        availableLocations,
        ...otherSelectProps
    } = props;

    const { locations: allLocations } = useLocation();
    const { setFieldValue } = useFormikContext();

    const locations = useMemo(() => {
        return availableLocations || allLocations;
    }, [availableLocations, allLocations]);

    const filteredLocations = useMemo(() => {
        if (filterLocations) {
            return filterLocations(locations);
        }
        return locations;
    }, [locations, filterLocations]);

    const options = useMemo(() => {
        return filteredLocations.map((location) => ({
            label: formatOptionLabel(location),
            value: location.id,
            data: location,
        }));
    }, [filteredLocations, formatOptionLabel]);

    const onChange = useCallback(
        (value: any, actionMeta: any) => {
            setFieldValue(name, value);

            if (props.onChange) {
                props.onChange(value, actionMeta);
            }
        },
        [name, props, setFieldValue],
    );

    const Component = selectComponent || UDSelect;

    return (
        <UDFormFieldWrapper
            name={name}
            containerProps={containerProps}
            label={label}
            labelProps={labelProps}
            required={required}>
            {({ field }: FieldProps<any>) => (
                <Component
                    {...otherSelectProps}
                    {...field}
                    options={options}
                    onChange={onChange}
                />
            )}
        </UDFormFieldWrapper>
    );
};

export default UDFormLocationSelect;
