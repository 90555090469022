import { OnChangeValue, SingleValue } from 'react-select';
import React, { useCallback, useState } from 'react';
import {
  championshipPlayerApplicationChangeNumber,
  championshipPlayerApplicationChangePosition,
  upsertChampionshipPlayerApplication,
} from '../../../store/championshipTeams/actions';
import { differenceInYears, format } from 'date-fns';

import { ApplicationStatus } from '../../../../applications/domain/enums/ApplicationStatus';
import { DecisionSelect } from "./styles";
import { PlayerApplication } from '../../../domain/interfaces/PlayerApplication';
import PlayerNumberInput from "../player-number-input";
import { PlayerPosition } from '../../../domain/enums/PlayerPosition';
import PositionSelect from '../position-select';
import { SelectOption } from '../../../../ud-form/components/select/component';
import { TeamPlayerAvatar } from '../team-card/styles';
import UDText from '../../../../ud-ui/components/text';
import getBirthDay from 'shared/utils/getBirthDay';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const defaultAvatar =
  'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png';

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
  height: 72px;
  background-color: #F7F7F7;
  border-radius: 8px;
  margin-bottom: 10px;
`;

type TeamPlayerCardProps = {
  teamId: number;
  index: number;
  player: PlayerApplication;
  isUpdating?: boolean;
};

const TeamPlayerCard = ({ index, player, isUpdating }: TeamPlayerCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [data, setData] = useState(player);

  const handleChange = useCallback(
    (option: SingleValue<SelectOption>) => {
      dispatch(
        upsertChampionshipPlayerApplication({
          id: data.id,
          teamId: data.teamId,
          championshipId: data.championshipId,
          state: option?.value.toUpperCase(),
          createUserOrUpdateApplication: {
            userId: data.player.id,
            name: data.player.firstName,
            surname: data.player.lastName,
            middleName: data.player.middleName,
            birthDate: data.player.birthDate,
            position: data.position ? data.position.toUpperCase() : data.position,
            number: data.number,
          },
        }),
      );
    },
    [dispatch, data],
  );

  const name = [
    data.player.lastName,
    data.player.firstName,
    data.player.middleName,
  ]
    .filter(Boolean)
    .join(' ');

  const birthDate = data.player.birthDate
    ? new Date(data.player.birthDate)
    : null;
  const full = getBirthDay(birthDate ?? undefined);



  const handleNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = parseInt(event.target.value);

      setData({
        ...data,
        number: number < 0 ? 0 : number,
      });

      dispatch(
        championshipPlayerApplicationChangeNumber({
          id: data.id,
          teamId: data.teamId,
          championshipId: data.championshipId,
          createUserOrUpdateApplication: {
            userId: data.player.id,
            name: data.player.firstName,
            surname: data.player.lastName,
            middleName: data.player.middleName,
            birthDate: data.player.birthDate,
            position: data.position ? data.position.toUpperCase() : data.position,
            number: number < 0 ? 0 : number,
          },
          state: data.status.toUpperCase(),
        }),
      );
    },
    [dispatch, data],
  );

  const handlePositionChange = useCallback(
    (option: OnChangeValue<SelectOption, false>) => {
      const position = option ? (option.value as PlayerPosition) : undefined;
      setData({
        ...data,
        position,
      });

      dispatch(
        championshipPlayerApplicationChangePosition({
          id: data.id,
          teamId: data.teamId,
          championshipId: data.championshipId,
          state: data.status,
          createUserOrUpdateApplication: {
            userId: data.player.id,
            name: data.player.firstName,
            surname: data.player.lastName,
            middleName: data.player.middleName,
            birthDate: data.player.birthDate,
            position: position ? position.toUpperCase() : position,
            number: data.number,
          },
        }),
      );
    },
    [dispatch, data],
  );

  const options = []
  if (player.status !== 'ACCEPTED') {
    options.push({
      value: ApplicationStatus.ACCEPTED,
      label: 'Подтвердить',
    })
  }
  if (player.status !== 'DECLINED') {
    options.push({
      value: ApplicationStatus.DECLINED,
      label: 'Отклонить',
    })
  }

  return (
    <CardContainer>
      <UDText type='subhead' className='bold flex-center' style={{ width: 60 }}>
        {index}
      </UDText>
      <TeamPlayerAvatar
        url={data.player.logoUrl || defaultAvatar}
        size={60}
        minSize={60}
        square
      />
      <UDText
        type='subhead' className='bold ml-5' style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '40%',
          maxWidth: 300,
        }}
      >
        {name}
      </UDText>
      <UDText
        type='subhead'
        className='bold'
        style={{
          flexBasis: '15%',
          whiteSpace: 'nowrap',
          width: 150,
          marginLeft: 20,
        }}
      >
        {full}
      </UDText>
      <PlayerNumberInput
        wrapperProps={{
          style: {
            marginLeft: 20,
            minWidth: 127,
          },
        }}
        name='number'
        placeholder='Номер игрока'
        min={0}
        value={data.number}
        onChange={handleNumberChange}
      />
      <PositionSelect
        name='position'
        value={data.position}
        placeholder='Амплуа'
        onChange={handlePositionChange}
      />

      <div style={{ marginLeft: 'auto' }}>
        <DecisionSelect
          divStyle={{ marginLeft: 20 }}
          isLoading={isUpdating}
          isDisabled={isUpdating}
          placeholder='Выбрать'
          options={options}
          onChange={handleChange}
        />
      </div>
    </CardContainer>
  );
};

export default TeamPlayerCard;
