/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect } from "react";
import { fetchLocations, fetchLocationsByFilter } from "../../store/actions";
import { resetFilter, setCurrentPage, setFilter, setLocationsPerPage, setTournamentId } from "../../store";

import { AppDispatch } from "../../../../store/store";
import EmptyState from "../components/empty-state";
import HeaderContent from "../components/header-content";
import LocationFilter from "../components/location-filter";
import LocationList from "../components/location-list";
import UDMainLayout from "modules/ud-ui/layout/main";
import { useDispatch } from "react-redux";
import useLocations from "../hooks/useLocations";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";

function LocationsListPage() {
  const dispatch = useDispatch<AppDispatch>()
  const {
    isLoaded,
    isLoading,
    locationLoadedLimit,
    allDataLoaded,
    locations,
    pages,
    tournamentId,
    filter,
    currentPage
  } = useLocations()
  const { info } = useOrganizer()

  // Первичная загрузка при монтировании или смене турнира
  useEffect(() => {
    if (!info) return
    if (info.id === tournamentId) {
      if (isLoaded) return
      if (isLoading) return;
    }

    dispatch(setTournamentId({ tournamentId: info.id }))
    dispatch(fetchLocations({ tournamentId: info.id, limit: locationLoadedLimit }))
  }, [dispatch, info, isLoaded, isLoading, locationLoadedLimit, tournamentId]);

  // Загрузка при изменении фильтра
  useEffect(() => {
    if (!info) return;
    if (!tournamentId) return;
    if (isLoading) return;

    if (filter.text) {
      dispatch(fetchLocationsByFilter({
        nameFilter: filter.text,
        tournamentId,
        limit: locationLoadedLimit
      }));
    } else if (!isLoaded) {
      dispatch(fetchLocations({
        tournamentId,
        limit: locationLoadedLimit
      }));
    }
  }, [info, dispatch, isLoaded, isLoading, filter.text, tournamentId, locationLoadedLimit]);

  // Подгрузка дополнительных данных
  useEffect(() => {
    if (!info) return;
    if (!tournamentId) return;
    if (isLoading) return;
    if (filter.text) {
      if (filter.locations.length === 0) return;
      if (filter.allDataLoaded) return;
    } else {
      if (locations.length === 0) return;
      if (allDataLoaded) return;
    }

    const shouldLoadMore = pages < 10 || (pages >= 10 && currentPage === pages);

    if (shouldLoadMore) {
      if (filter.text) {
        dispatch(fetchLocationsByFilter({
          nameFilter: filter.text,
          tournamentId,
          limit: locationLoadedLimit,
          offset: filter.locations.length
        }));
      } else {
        dispatch(fetchLocations({
          tournamentId,
          limit: locationLoadedLimit,
          offset: locations.length
        }));
      }
    }
  }, [
    info,
    tournamentId,
    allDataLoaded,
    filter.allDataLoaded,
    dispatch,
    locationLoadedLimit,
    locations.length,
    filter.locations.length,
    isLoading,
    pages,
    filter.text,
    currentPage
  ]);

  const changeFilter = useCallback((changes: { search: string }) => {
    if (!info) return
    const { search } = changes
    dispatch(setFilter({ text: search }))
    if (search) {
      dispatch(fetchLocationsByFilter({ nameFilter: search, tournamentId: info.id, limit: locationLoadedLimit }))
    } else {
      dispatch(resetFilter())
    }
  }, [dispatch, info, locationLoadedLimit]);

  const handlePageChange = useCallback((page: number) => {
    dispatch(setCurrentPage({ currentPage: page }));
  }, [dispatch]);

  const handlePerPageChange = useCallback((value: number) => {
    dispatch(setLocationsPerPage({ locationsPerPage: value }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetFilter())
    };
  }, [dispatch]);

  if (isLoaded && locations.length === 0) {
    return (
      <>
        <UDMainLayout headerContent={<HeaderContent />}>
          <EmptyState />
        </UDMainLayout>
      </>

    )
  }

  return (
    <UDMainLayout headerContent={<HeaderContent />}>

      <LocationFilter
        onFilter={changeFilter}
        onPerPageChange={handlePerPageChange}
      />
      <LocationList
        onPageChange={handlePageChange}
      />
    </UDMainLayout>
  );
}

export default LocationsListPage;
