import { deleteLocation, fetchLocations, fetchLocationsByFilter, upsertLocation } from "./actions";

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { LocationsState } from "./index";
import { toast } from "react-toastify";

type ReducerBuilder = ActionReducerMapBuilder<LocationsState>;

export function createFetchLocationsReducer(builder: ReducerBuilder) {
  builder.addCase(fetchLocations.pending, (state) => {
    state.isLoading = true;
    state.isLoaded = false;
  });

  builder.addCase(fetchLocations.fulfilled, (state, action) => {
    const existingLocations = state.locations.map(location => location.id);
    const newLocations = action.payload.filter(location => !existingLocations.includes(location.id));

    if (state.locations.length === 0) {
      state.locations = action.payload;
    } else {
      state.locations = [...state.locations, ...newLocations];
    }

    state.pages = Math.ceil(state.locations.length / state.locationsPerPage);
    if (state.currentPage === 0) {
      state.currentPage = state.pages > 0 ? 1 : 0;
    }

    state.allDataLoaded = newLocations.length === 0 || action.payload.length < state.locationLoadedLimit;
    state.isLoading = false;
    state.isLoaded = true;
  });

  builder.addCase(fetchLocations.rejected, (state) => {
    state.isLoading = false;
    state.isLoaded = false;
    toast.error('Не удалось загрузить локации');
  });
}

export function createFetchLocationsByFilterReducer(builder: ReducerBuilder) {
  builder.addCase(fetchLocationsByFilter.pending, (state) => {
    state.filter.isLoading = true;
    state.filter.isLoaded = false;
  });

  builder.addCase(fetchLocationsByFilter.fulfilled, (state, action) => {
    state.filter.locations = action.payload;
    state.filter.pages = Math.ceil(state.filter.locations.length / state.locationsPerPage);
    state.filter.currentPage = state.filter.pages > 0 ? 1 : 0;
    state.filter.allDataLoaded = state.filter.locations.length < state.locationLoadedLimit;
    state.filter.isLoading = false;
    state.filter.isLoaded = true;
  });

  builder.addCase(fetchLocationsByFilter.rejected, (state) => {
    state.filter.isLoading = false;
    state.filter.isLoaded = false;
    toast.error('Не удалось выполнить поиск локаций');
  });
}

export function createDeleteLocationReducer(builder: ReducerBuilder) {
  builder.addCase(deleteLocation.pending, (state) => {
    state.isProcessing = true;
    state.isProcessed = false;
    state.processName = 'deleteLocation';
  });

  builder.addCase(deleteLocation.fulfilled, (state, action) => {
    state.locations = state.locations.filter(location => location.id !== action.payload.id);
    state.pages = Math.ceil(state.locations.length / state.locationsPerPage);

    if (state.currentPage > state.pages) {
      state.currentPage = Math.max(1, state.pages);
    }

    if (state.filter.text) {
      state.filter.locations = state.filter.locations.filter(location => location.id !== action.payload.id);
      state.filter.pages = Math.ceil(state.filter.locations.length / state.locationsPerPage);

      if (state.filter.currentPage > state.filter.pages) {
        state.filter.currentPage = Math.max(1, state.filter.pages);
      }
    }

    state.isProcessing = false;
    state.isProcessed = true;
    state.processName = undefined;
    toast.success('Локация успешно удалена');
  });

  builder.addCase(deleteLocation.rejected, (state) => {
    state.isProcessing = false;
    state.isProcessed = false;
    state.processName = undefined;
  });
}

export function createUpsertLocationReducer(builder: ReducerBuilder) {
  builder.addCase(upsertLocation.pending, (state) => {
    state.isProcessing = true;
    state.isProcessed = false;
    state.processName = 'upsertLocation';
  });

  builder.addCase(upsertLocation.fulfilled, (state, action) => {
    const locationIndex = state.locations.findIndex(location => location.id === action.payload.id);
    const isFilterActive = Boolean(state.filter.text);

    if (locationIndex !== -1) {
      state.locations[locationIndex] = action.payload;
      if (isFilterActive) {
        const filterIndex = state.filter.locations.findIndex(location => location.id === action.payload.id);
        if (filterIndex !== -1) {
          state.filter.locations[filterIndex] = action.payload;
        }
      }
    } else {
      state.locations.push(action.payload);
      if (isFilterActive) {
        state.filter.locations.push(action.payload);
        state.filter.pages = Math.ceil(state.filter.locations.length / state.locationsPerPage);
      } else {
        state.pages = Math.ceil(state.locations.length / state.locationsPerPage);
      }
    }

    state.isProcessing = false;
    state.isProcessed = true;
    state.processName = undefined;
    toast.success('Локация успешно сохранена');
  });

  builder.addCase(upsertLocation.rejected, (state) => {
    state.isProcessing = false;
    state.isProcessed = false;
    state.processName = undefined;
    toast.error('Не удалось сохранить локацию');
  });
}