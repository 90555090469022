/** @jsxImportSource @emotion/react */

import * as yup from 'yup';

import { mapAddressToDadataArea, mapDadataAreaToAddress } from 'shared/features/dadata-address-autocomplete/infra/mappers/dadata-area.mapper';
import { useCallback, useEffect, useState } from 'react';

import { DadataAreaType } from 'shared/features/dadata-address-autocomplete/domain/entities/dadata-area.entity';
import { Formik } from 'formik';
import { Location } from '../../domain/interfaces/location';
import { LocationFormContainer } from './styles';
import UDButton from '../../../ud-ui/components/button';
import { UDFormAddressSelect } from '../../../ud-form/components/select';
import UDFormDropZone from '../../../ud-form/components/dropzone';
import UDFormInput from '../../../ud-form/components/input';
import UDFormTextearea from '../../../ud-form/components/input/textarea';
import { UpsertLocationInput } from '../../domain/interfaces/actions';
import { css } from '@emotion/react';
import { isEmptyAddress } from 'modules/core/helpers/isEmptyAddress';
import { toast } from 'react-toastify';

type LocationFormProps = {
    location?: Location;
    isLoading: boolean;
    onSubmit: (values: UpsertLocationInput) => Promise<void>;
    onCancel: () => void;
    tournamentId?: number;
};

const locationValidator = yup.object().shape({
    name: yup.string().required('Название обязательно'),
    address: yup.object().nullable().required('Адрес обязателен'),
    images: yup.array()
        .max(5, 'Максимум 5 фотографий')
        .test('unique', 'Изображения должны быть уникальными', function (value) {
            if (!value) return true;

            const urls = value.map(img => {
                if (img instanceof File) return img.name;
                if (typeof img === 'string') return img;
                return img.externalUrl;
            });

            return urls.length === new Set(urls).size;
        }),
});

function LocationForm({ location, isLoading, onSubmit, onCancel, tournamentId }: LocationFormProps) {
    const [descriptionHeight, setDescriptionHeight] = useState<string>('flex-grow: 1');

    useEffect(() => {
        const description = document.getElementById('description');
        if (!description) { return; }

        const observer = new MutationObserver(() => {
            setDescriptionHeight(description.style.height);
        });

        observer.observe(description, { attributes: true, attributeFilter: ['style'] });

        return () => {
            observer.disconnect();
        };
    }, []);

    const initialValues = location ? {
        name: location.name,
        address: location.address && !isEmptyAddress(location.address) ? mapAddressToDadataArea(location.address) : null,
        description: location.description || '',
        images: location.images || [],
    } : {
        name: '',
        address: null,
        description: '',
        images: [],
    };

    const handleSubmit = useCallback((values: typeof initialValues) => {
        const effectiveTournamentId = location?.tournamentId || tournamentId;

        if (!effectiveTournamentId) {
            toast.error('ID организатора не найден, попробуйте обновить страницу');
            throw new Error('Tournament ID is required for location');
        }

        // Проверяем уникальность изображений перед отправкой
        const imageUrls = values.images.map(img =>
            img instanceof File ? img.name : (typeof img === 'string' ? img : img.externalUrl)
        );


        if (imageUrls.length !== new Set(imageUrls).size) {
            toast.error('Обнаружены дубликаты изображений');
            return;
        }

        console.log({ valuesImages: values.images, images: values.images.map(img => img instanceof File ? img : img.externalUrl) });

        const locationData: UpsertLocationInput = {
            id: location?.id,

            name: values.name,
            description: values.description,
            images: values.images.map(img => img instanceof File ? img : img.externalUrl),
            tournamentId: effectiveTournamentId,
            address: values.address ? mapDadataAreaToAddress(values.address) : undefined,
        };
        onSubmit(locationData);
    }, [location?.id, location?.tournamentId, tournamentId, onSubmit]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={locationValidator}
            onSubmit={handleSubmit}
        >
            {(formProps) => {
                const handleDeleteImage = (index: number) => {
                    const newImages = [...formProps.values.images];
                    newImages.splice(index, 1);
                    formProps.setFieldValue('images', newImages);
                };

                return (
                    <form
                        className='d-flex flex-column justify-content-between h-100'
                        onSubmit={formProps.handleSubmit}
                    >
                        <LocationFormContainer>
                            <div className='row'>
                                <div className='col-6 flex-column'>
                                    <UDFormInput
                                        name='name'
                                        label='Название*'
                                        placeholder='Введите название места проведения'
                                    />

                                    <div className='location-data' style={{ gridTemplateColumns: '1fr' }}>
                                        <UDFormAddressSelect
                                            name='address'
                                            label='Город и адрес*'
                                            placeholder='Место проведения турниров'
                                            onlyCity={false}
                                            allowedTypes={[
                                                DadataAreaType.REGION,
                                                DadataAreaType.CITY,
                                                DadataAreaType.STREET,
                                                DadataAreaType.HOUSE
                                            ]}
                                        />
                                    </div>

                                    <div className='location-data col-12 d-flex' style={{ gridTemplateColumns: '1fr' }} css={css`${descriptionHeight}`}>
                                        <UDFormTextearea
                                            name='description'
                                            label='Описание'
                                            placeholder='Опишите локацию как можно подробнее, расскажите о преимуществах'
                                            style={{ height: '100%' }}
                                            id='description'
                                            containerProps={{ className: 'flex-column' }}
                                            wrapperProps={{ style: { flexGrow: 1 } }}
                                        />
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <UDFormDropZone
                                        name='images'
                                        label='Загрузите фото локации (до 5 шт.)'
                                        value={location?.images || []}
                                        isCover={false}
                                        multiple
                                        enableDragAndDrop
                                        onChangeInterceptor={(files) => files.slice(0, 5)}
                                        onDeleteImage={handleDeleteImage}
                                        formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
                                    />
                                </div>
                            </div>

                            <div className='d-flex justify-content-between mb-6' style={{ width: '50%', paddingRight: 24, marginTop: 60 }}>
                                <UDButton
                                    type='submit'
                                    variant='primary'
                                    disabled={isLoading}
                                    loading={isLoading}
                                    className='mr-4'
                                    style={{ width: '50%' }}
                                >
                                    Сохранить
                                </UDButton>

                                <UDButton
                                    type='button'
                                    variant='secondary'
                                    disabled={isLoading}
                                    onClick={onCancel}
                                    style={{ width: '50%' }}
                                >
                                    Отменить
                                </UDButton>
                            </div>

                        </LocationFormContainer>
                    </form>
                );
            }}
        </Formik>
    );
}

export default LocationForm;