/** @jsxImportSource @emotion/react */

import { AppDispatch, history } from "../../../../../../../store/store";
import { Board, Template as ITemplate } from "../../../../../../core/domain/interfaces/supabase/overlay/entity";
import {
  Container,
  LeftContainer,
  RightContainer,
} from "../../../../../../ud-ui/components/fabric-canvas/view-boards/styles";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchTemplateWithBoards, fetchUserTemplates } from "../../../../../../template-editor/store/templates/actions";
import { selectBoardsByTemplateId, selectTemplateById } from "../../../../../../template-editor/store/templates/selectors";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import BoardItem from "../../../../../../ud-ui/components/fabric-canvas/view-boards/boards-list/board-item";
import BoardsList from "../../../../../../ud-ui/components/fabric-canvas/view-boards/boards-list/boards-list";
import CanvasComponent from "./components/canvasFabric";
import ChainIcon from "../../../../../../ud-ui/components/icon/chain-icon";
import { ChampionshipSettings } from "../../../../../domain/interfaces/ChampionshipSettings";
import EmptyButton from "../../../../../../ud-ui/components/emptyButton";
import { Game } from "../../../../../../games/domain/interfaces/Game";
import { Pages } from "../../../../../../navigation/domain/enums/pages";
import { SyncLoader } from "react-spinners";
import { TeamByMatchId } from "../../../../../domain/interfaces/TeamByMatchId";
import UDHeaderViewBoards from "../../../../../../ud-ui/components/fabric-canvas/view-boards/right/UDHeaderViewBoards";
import UDIcon from "../../../../../../ud-ui/components/icon";
import UDText from "../../../../../../ud-ui/components/text";
import _ from "lodash";
import { toast } from 'react-toastify';
import tourneysRepository from "../../../../../domain/repositories/tourneysRepository";
import { useAuth } from "../../../../../../auth/ui/hooks/useAuth";
import useTemplates from "../../../../../../template-editor/ui/hooks/useTemplates";

type TemplateProps = {
  game: Game,
  championship: {
    id: number
    settings: ChampionshipSettings,
    tournamentId: number
    address?: {
      city?: string
    },
    name: string
  },
}

function Template({ game, championship }: TemplateProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { isLoaded, isLoading } = useTemplates()
  const template = useSelector(selectTemplateById(championship?.settings?.overlayId ?? undefined))
  const boards = useSelector(selectBoardsByTemplateId(template?.id), shallowEqual)
  const [activeBoard, setActiveBoard] = useState<Board>()
  const [teamsPlayers, setTeamsPlayers] = useState<TeamByMatchId[]>()
  const isDev = process.env.NODE_ENV === 'development'
  const { user } = useAuth()
  const isAdmin = useMemo(() => {
    if (isDev) return true
    if (!user) return false
    return user.id === 20
  }, [isDev, user])

  useEffect(() => {
    tourneysRepository.fetchTeamPlayersByMatchId(game.id).then((teams) => {
      if (!teams) return
      if (teams[0].teamId === game.teams[0]?.id && teams[1].teamId === game.teams[1]?.id) {
        setTeamsPlayers(teams)
      } else if (teams[0].teamId === game.teams[1]?.id && teams[1].teamId === game.teams[0]?.id) {
        setTeamsPlayers(teams.reverse())
      }
    })
  }, [game.id, game.teams])

  useEffect(() => {
    if (isLoaded) return
    if (!championship.settings.overlayId) return
    dispatch(fetchTemplateWithBoards({ templateId: championship.settings.overlayId }))
  }, [championship.settings.overlayId, championship.tournamentId, dispatch, isAdmin, isLoaded])

  useEffect(() => {
    if (!template) return
    if (boards.length <= 0) return
    console.log({ message: 'setActiveBoard to first board', boards, template })
    setActiveBoard(boards[0])
  }, [boards, template])

  const handleCreateLink = useCallback((data?: {
    boardId?: number,
    isLocked?: boolean,
    isHidePanel?: boolean,
    fullScreenPanel?: boolean
  }) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      toast.error('Необходимо авторизоваться');
      return;
    }

    const {
      boardId,
      isLocked = false,
      isHidePanel = false,
      fullScreenPanel = false,
    } = data ?? {}

    const encodedToken = window.btoa(token);
    const domain = fullScreenPanel ? 'control-panel' : 'cover';
    navigator.clipboard.writeText(`${window.location.origin}/${domain}/${game.id}/?token=${encodedToken}${data ? `&boardId=${boardId}&locked=${isLocked}&hidePanel=${isHidePanel}` : ''}`)
      .then(() => {
        toast.success('Ссылка скопирована');
      })
      .catch(() => {
        toast.error('Не удалось скопировать ссылку');
      });
  }, [game.id]);

  const ItemList = useMemo(() => {
    if (!boards) return undefined;

    return boards.map((board) => {
      const button = board.type === 'tv' ? (
        <EmptyButton
          icon={<ChainIcon />}
          onClick={() => handleCreateLink({
            boardId: board.id,
            fullScreenPanel: true,
          })}
        />
      ) : null

      return (
        <BoardItem
          key={board.id}
          board={board}
          isEditable={false}
          onClick={() => setActiveBoard(board)}
          isActive={board.id === activeBoard?.id}
          additionalButton={button}
        />
      );
    });
  }, [activeBoard?.id, boards, handleCreateLink]);

  const onClickGetLinkOverlay = () => {
    handleCreateLink()
  }

  const redirectToSettings = useCallback(() => {
    history.push(Pages.TOURNEYS.builders.edit(championship.id, 'common'), { highlight: ['template'] });
  }, [championship.id]);

  return (
    <div className={'px-6 h-100'}>
      <Container>
        {(isLoading || !teamsPlayers) ? (
          <div className={'flex-center w-100'} style={{ height: 450 }}><SyncLoader /></div>
        ) : (
          template === undefined ? (
            <div className='w-100 flex-center flex-column' style={{ height: 400 }}>
              <UDText type={'headline'}>Не выбран шаблон</UDText>
              <UDText
                type={'subhead'}
                style={{ color: '#007AFF', cursor: "pointer" }}
                onClick={redirectToSettings}
              >
                Выберите шаблон из списка
              </UDText>
            </div>
          ) : (
            <>
              <LeftContainer>
                <BoardsList
                  items={ItemList ?? []}
                  headerText={'Табло'}
                />
              </LeftContainer>
              <RightContainer>
                <UDHeaderViewBoards
                  mainText={activeBoard?.name}
                  buttons={[
                    <EmptyButton onClick={onClickGetLinkOverlay} icon={<UDIcon name='chain' />}>
                      Скопировать ссылку
                    </EmptyButton>,
                  ]}
                />
                <CanvasComponent
                  game={game}
                  teamsPlayers={teamsPlayers}
                  championship={championship}
                  resolution={template?.resolution}
                  scheme={activeBoard ? { version: activeBoard.version, objects: activeBoard.objects } : undefined}
                />
              </RightContainer>
            </>
          )
        )}
      </Container>
    </div>
  );
}

export default Template;
