import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  createDeleteLocationReducer,
  createFetchLocationsByFilterReducer,
  createFetchLocationsReducer,
  createUpsertLocationReducer,
} from './reducers';

import { Location } from '../domain/interfaces/location';

export interface LocationsState {
  locations: Location[];

  locationsPerPage: number;
  pages: number;
  currentPage: number;

  isLoading: boolean;
  isLoaded: boolean;

  allDataLoaded: boolean;
  locationLoadedLimit: number;

  isProcessing: boolean;
  isProcessed: boolean;
  processName?: string;

  filter: {
    text: string;
    locations: Location[];

    pages: number;
    currentPage: number;

    isLoading: boolean;
    isLoaded: boolean;
    allDataLoaded: boolean;
  };

  tournamentId?: number;
}

const initialState: LocationsState = {
  locations: [],

  locationsPerPage: Number(localStorage.getItem('locationsPerPage')) || 10,
  pages: 0,
  currentPage: 0,

  isLoading: false,
  isLoaded: false,

  allDataLoaded: false,
  locationLoadedLimit: 50,

  isProcessing: false,
  isProcessed: false,

  filter: {
    text: '',
    locations: [],

    pages: 0,
    currentPage: 0,

    isLoading: false,
    isLoaded: false,
    allDataLoaded: false,
  },

  tournamentId: undefined,
};

export const LocationsSlice = createSlice({
  name: 'locationsSlice',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
      const isFilterActive = Boolean(state.filter.text)
      if (isFilterActive) {
        state.filter.currentPage = action.payload.currentPage
      } else {
        state.currentPage = action.payload.currentPage
      }
    },
    setLocationsPerPage: (state, action: PayloadAction<{ locationsPerPage: number }>) => {
      const isFilterActive = Boolean(state.filter.text)
      state.locationsPerPage = action.payload.locationsPerPage
      localStorage.setItem('locationsPerPage', state.locationsPerPage.toString())

      if (isFilterActive) {
        state.filter.pages = Math.ceil(state.filter.locations.length / state.locationsPerPage)
        const currentPage = state.filter.currentPage;
        state.filter.currentPage = currentPage > state.filter.pages ? state.filter.pages : currentPage
      } else {
        state.pages = Math.ceil(state.locations.length / state.locationsPerPage)
        const currentPage = state.currentPage;
        state.currentPage = currentPage > state.pages ? state.pages : currentPage
      }
    },
    setFilter: (state, action: PayloadAction<{ text: string }>) => {
      state.filter.text = action.payload.text
    },
    resetFilter: (state) => {
      state.filter.text = ''
      state.filter.locations = []

      state.filter.pages = 0
      state.filter.currentPage = 0

      state.filter.isLoading = false
      state.filter.isLoaded = false
      state.filter.allDataLoaded = false

      state.pages = Math.ceil(state.locations.length / state.locationsPerPage)
      const currentPage = state.currentPage;
      state.currentPage = currentPage > state.pages ? state.pages : currentPage
    },
    resetProcess: (state) => {
      state.isProcessing = false
      state.isProcessed = false
      state.processName = undefined
    },
    setTournamentId: (state, action: PayloadAction<{ tournamentId: number }>) => {
      state.tournamentId = action.payload.tournamentId
      state.locations = []
      state.currentPage = 0
      state.pages = 0
    },
  },
  extraReducers: (builder) => {
    createFetchLocationsReducer(builder);
    createFetchLocationsByFilterReducer(builder);
    createUpsertLocationReducer(builder);
    createDeleteLocationReducer(builder);
  },
});

export const {
  setLocationsPerPage,
  setCurrentPage,
  setFilter,
  resetFilter,
  setTournamentId,
  resetProcess,
} = LocationsSlice.actions;

export default LocationsSlice.reducer;