import {
  Championship,
  GameInfoMapped,
  Match,
  Member,
  Organizer,
  Team,
  Teams,
} from '../interfaces/gameInfo/gameInfoMapped';

import { ChampionshipStats } from '../interfaces/championshipStats';
import { IGameInfoDTO } from '../interfaces/gameInfo/gameInfoDTO';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

export function gameInfoDataMapper(gameInfo: IGameInfoDTO): GameInfoMapped {
  return {
    match: extractMatch(gameInfo),
    teams: extractTeams(gameInfo),
    organizer: extractOrganizer(gameInfo),
    championship: extractChampionship(gameInfo),
  };
}

function extractMatch(gameInfo: IGameInfoDTO): Match {
  const date = gameInfo?.date ? new Date(gameInfo?.date) : undefined
  const location = gameInfo?.location

  return {
    id: gameInfo.id,
    dateInfo: {
      fullDate: gameInfo.date,
      date: date ? format(date, 'd MMMM', { locale: ru }) : 'Неизвестная дата',
      time: date ? format(date, 'HH:mm') : 'Неизвестное время',
    },
    address: {
      city: location?.address?.city?.split(' ')[1] ?? 'Неизвестный город',
      country: location?.address?.country,
      region: location?.address?.region,
    },
  }
}

function extractTeams(gameInfo: IGameInfoDTO): Teams {
  return {
    firstTeam: extractTeam(gameInfo, 0),
    secondTeam: extractTeam(gameInfo, 1),
  }
}

function extractTeam(gameInfo: IGameInfoDTO, teamIndex: number): Team | undefined {
  if (!gameInfo.teamMatches) return undefined

  const id = gameInfo.teamMatches[teamIndex].team.id
  const lastGameResult = gameInfo.championship?.championshipTable.rows.find((row) => row.team.id === id)?.lastGamesResults

  return {
    id: gameInfo.teamMatches[teamIndex]?.team?.id,
    name: gameInfo.teamMatches[teamIndex]?.team?.name,
    logo: gameInfo.teamMatches[teamIndex]?.team?.emblem?.externalUrl,
    lastGamesResults: lastGameResult ?? [],
    members: extractMembers(gameInfo, teamIndex),
    score: 0,
    fouls: 0,
  }
}

function extractMembers(gameInfo: IGameInfoDTO, teamIndex: number): Member[] {
  if (!gameInfo.teamMatches) return []

  const championshipId = gameInfo.championship?.id
  const members = gameInfo.teamMatches[teamIndex].team.championshipApplications
  const filteredMembers: Member[] = []

  for (const member of members) {
    if (member.championship.id !== championshipId) continue
    if (member.state !== 'ACCEPTED') continue
    filteredMembers.push({
      id: member.user.id,
      name: member.user.name,
      surname: member.user.surname,
      middleName: member.user.middleName ?? '',
      photo: getPhoto(gameInfo, member.user.id, teamIndex),
      position: member.position,
      number: member.number,
      isInvolved: isInvolved(gameInfo, member.user.id),
      qtyGoalsMatch: 0,
      qtyYellowCardsMatch: 0,
      qtyRedCardsMatch: 0,
      qtyFoulsMatch: 0,
      qtyYellowCardsChampionship: 0,
      qtyRedCardsChampionship: 0,
      qtyGoalsChampionship: 0,
    })
  }

  return filteredMembers
}

function isInvolved(gameInfo: IGameInfoDTO, memberId: number) {
  const member = gameInfo.participants?.find((participant) => participant.user.id === memberId)
  if (!member) return false
  return member.isInvolved
}

function getPhoto(gameInfo: IGameInfoDTO, memberId: number, teamIndex: number) {
  if (!gameInfo.teamMatches) return 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png'
  const member = gameInfo.teamMatches[teamIndex].team.members.find((member) => member.user.id === memberId)
  if (!member) return 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png'
  return member.user.photo?.url ?? 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png'
}

function getStats(champStats: ChampionshipStats[], memberId: number): {
  qtyYellowCardsChampionship: number, qtyRedCardsChampionship: number, qtyGoalsChampionship: number
} {
  const stats = champStats
  if (!stats) return { qtyYellowCardsChampionship: 0, qtyRedCardsChampionship: 0, qtyGoalsChampionship: 0 }
  const memberStats = stats.find((stat) => stat.user.id === memberId)
  if (!memberStats) return { qtyYellowCardsChampionship: 0, qtyRedCardsChampionship: 0, qtyGoalsChampionship: 0 }
  return {
    qtyYellowCardsChampionship: memberStats.yellowcards,
    qtyRedCardsChampionship: memberStats.redcards,
    qtyGoalsChampionship: memberStats.goals,
  }
}

function extractOrganizer(gameInfo: IGameInfoDTO): Organizer | undefined {
  if (!gameInfo.tournament) return undefined

  return {
    name: gameInfo.tournament?.name ?? 'Неизвестно',
    logo: gameInfo.tournament?.images?.[0]?.externalUrl,
    id: gameInfo.tournament?.id,
  }
}

function extractChampionship(gameInfo: IGameInfoDTO): Championship | undefined {
  if (!gameInfo.championship) return undefined

  const overlayIdStr = gameInfo.championship.settings.find((setting) => setting.name === 'overlay_id')?.value

  return {
    id: gameInfo.championship.id,
    name: gameInfo.championship.name,
    settings: gameInfo.championship.settings,
    overlayId: overlayIdStr ? +overlayIdStr : null,
  }
}
