import { differenceInYears, format } from 'date-fns';

import i18n from 'i18next';

export default function getBirthDay(birthDate?: Date) {
    const birthDateString = birthDate ? format(birthDate, 'dd.MM.yyyy') : '';
    const years = birthDate ? differenceInYears(new Date(), birthDate) : null;
    const yearsString = years ? i18n.t('ages', { count: years }) : '';
    return birthDateString ? `${birthDateString} (${yearsString})` : '-';
}
