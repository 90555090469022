/** @jsxImportSource @emotion/react */

import CrossIcon from '../../../../ud-ui/components/icon/cross-icon';
import EmptyButton from '../../../../ud-ui/components/emptyButton';
import { Image } from '../types';
import React from 'react';
import UDText from "../../../../ud-ui/components/text";
import classNames from 'classnames';
import { formatBytes } from '../utils/formatBytes';
import { imageContainerStyles } from '../styles';

interface ImageItemProps {
    img: Image;
    index: number;
    onDelete: (index: number) => void;
    onDeleteImage?: (index: number) => void;
    isCover: boolean;
    multiple?: boolean;
}

export const ImageItem: React.FC<ImageItemProps> = ({
    img,
    index,
    onDelete,
    onDeleteImage,
    isCover,
    multiple
}) => (
    <div css={imageContainerStyles}>
        {onDeleteImage && (
            <div>
                <EmptyButton
                    type="button"
                    icon={<CrossIcon />}
                    onClick={() => onDelete(index)}
                    className="delete-button"
                />
            </div>
        )}
        <div>
            <img
                className="br-8"
                src={img.url || img.externalUrl}
                alt={`Изображение ${index + 1}`}
            />
            {isCover && index === 0 && (
                <div>
                    <span
                        className="p-1 text caption-2 link active bgc-SurfaceSpaceCadet50 opacity-9 br-8"
                        style={{
                            top: '8px',
                            left: '8px',
                            position: 'absolute',
                        }}
                    >
                        Обложка
                    </span>
                </div>
            )}
        </div>
        {img.name && (
            <div className={classNames('d-flex flex-column', { 'ml-5': !multiple })}>
                <UDText
                    style={multiple ? {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: 100
                    } : {}}
                    type="subhead"
                >
                    {img.name}
                </UDText>
                <span className="css-1jigk text footnote">
                    {img.size ? formatBytes(img.size) : ''}
                </span>
                <span className="css-1jigk text footnote" style={{ textAlign: 'end' }}>
                    100%
                </span>
                <progress style={{ width: 'auto' }} max="100" value="100" />
            </div>
        )}
    </div>
);