import * as yup from 'yup';

import { AuthSignInPayload } from '../interfaces/AuthSignInPayload';
import { TFunction } from 'i18next';
import { validPhoneRegex } from 'modules/ud-form/components/phone-input/validators/phone';

export const authSignInValidator = (t: TFunction): yup.SchemaOf<AuthSignInPayload> => yup
  .object()

  .shape({
    username: yup.string()
      .required(t('signIn.validationError.phoneRequired'))
      .matches(validPhoneRegex, t('signIn.validationError.phoneInvalid')),
    password: yup.string()
      .required(t('signIn.validationError.passwordRequired')),

    to: yup.string().optional(),
  });
