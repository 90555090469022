import { css } from '@emotion/react';

export const imageContainerStyles = css`
  position: relative;

  .delete-button {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 4px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
      height: 12px;
      fill: currentColor;
    }
  }

  &:hover .delete-button {
    opacity: 1;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
`;