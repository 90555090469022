import React from 'react';
import UDText from "../../text";
import styled from '@emotion/styled';

const ItemContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 72px;
  min-height: 72px;
  background-color: ${props => props.backgroundColor || '#F7F7F7'};
  border-radius: 8px;
`;

const AvatarWrapper = styled.div<{ size?: number }>`
  width: ${props => props.size || 60}px;
  height: ${props => props.size || 60}px;
  min-width: ${props => props.size || 60}px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

`;

export interface UDListItemAction {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
}

export interface UDListItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
    index?: number | string;
    avatar?: string | React.ReactNode;
    title?: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    additionalFields?: React.ReactNode[];
    actions?: UDListItemAction[];
    backgroundColor?: string;
    avatarSize?: number;
    showIndex?: boolean;
    indexWidth?: number;
    titleWidth?: string | number;
}

const UDListItem: React.FC<UDListItemProps> = ({
    index,
    avatar,
    title,
    subtitle,
    additionalFields = [],
    actions = [],
    backgroundColor,
    avatarSize,
    showIndex = false,
    indexWidth = 60,
    titleWidth = '40%',
    ...rest
}) => {
    return (
        <ItemContainer className='UDListItem' backgroundColor={backgroundColor} {...rest} style={{ padding: !showIndex ? '0 20px' : '0 20px 0 0', ...rest.style }}>
            {showIndex && (
                <UDText
                    type='subhead'
                    className='bold flex-center'
                    style={{ width: indexWidth, minWidth: indexWidth }}
                >
                    {index}
                </UDText>
            )}

            {avatar && (
                <AvatarWrapper size={avatarSize}>
                    {typeof avatar === 'string' ? <img src={avatar} alt={typeof title === 'string' ? title : 'avatar'} /> : avatar}
                </AvatarWrapper>
            )}


            {title && (
                <UDText
                    type='subhead'
                    className='bold ml-5'
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: titleWidth,
                        maxWidth: 300,
                        fontWeight: 500,
                    }}
                >
                    {title}
                </UDText>
            )}

            {subtitle && (
                <UDText
                    type='subhead'
                    className='bold'
                    style={{
                        flexBasis: '50%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginLeft: 20,
                        fontWeight: 500,
                    }}
                >
                    {subtitle}
                </UDText>
            )}

            {additionalFields.map((field, idx) => (
                <React.Fragment key={idx}>
                    {field}
                </React.Fragment>
            ))}

            {actions.length > 0 && (
                <div style={{ marginLeft: 'auto', display: 'flex', gap: '10px' }}>
                    {actions.map((action, idx) => (
                        <button
                            key={idx}
                            onClick={action.onClick}
                            disabled={action.disabled}
                            style={{
                                padding: '8px 16px',
                                borderRadius: '4px',
                                border: 'none',
                                backgroundColor: '#007AFF',
                                color: 'white',
                                cursor: action.disabled ? 'not-allowed' : 'pointer',
                                opacity: action.disabled ? 0.5 : 1,
                            }}
                        >
                            {action.loading ? 'Загрузка...' : action.label}
                        </button>
                    ))}
                </div>
            )}
        </ItemContainer>
    );
};

export default UDListItem;
