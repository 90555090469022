import { selectLoad, selectLocations } from '../../store/selectors';

import { useSelector } from 'react-redux';

export const useLocation = () => {
    const { isLoading, isLoaded } = useSelector(selectLoad);
    const locations = useSelector(selectLocations);

    return {
        isLoading,
        isLoaded,
        locations
    };
};