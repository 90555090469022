/** @jsxImportSource @emotion/react */

import { ModalButton, tooltipStyles } from "../styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { selectActiveModal, selectFabricManager } from "../../../../../store/editor/selectors";
import { useDispatch, useSelector } from "react-redux";

import ArrowBracketIcon from "../../../../../../ud-ui/components/icon/arrow-bracket-icon";
import BaseModal from "../../../elements/modals/baseModal";
import EmptyButton from "../../../../../../ud-ui/components/emptyButton";
import { ModalState } from "../../../../../domain/interface/modalState";
import { Placeholders } from "../../../../../../ud-ui/components/fabric-canvas/addons/placeholders";
import RectanglePlusIcon from "../../../../../../ud-ui/components/icon/rectangle-plus-icon";
import SnapToGrid from "../../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import UDTooltip from "../../../../../../ud-form/components/tooltip";
import { carouselPlaceholderList } from "./carousel-placeholder-list";
import { categoryList } from "./category-list";
import { commonPlaceholder } from "../../../../../../ud-ui/components/fabric-canvas/types/placeholders/createArgument";
import { commonPlaceholderList } from "./common-placeholder-list";
import { complexPlaceholdersList } from "./complex-placeholders-list";
import { eventPlaceholderList } from "./event-placeholder-list";
import history from "../../../../../../ud-ui/components/fabric-canvas/addons/history";
import { selectActiveBoard } from "../../../../../store/templates/selectors";
import { setActiveModal } from "modules/template-editor/store/editor/editor";
import useLocalStorage from "../../../../hooks/useLocalStorage";

export function AddPlaceholder() {
  const dispatch = useDispatch();
  const activeBoard = useSelector(selectActiveBoard);
  const activeModal = useSelector(selectActiveModal);
  const { getData } = useLocalStorage();
  const fabricManager = useSelector(selectFabricManager);
  const [isSelected, setSelected] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [categoryModalState, setCategoryModalState] = useState<ModalState>({ isModalOpen: false, modalStyle: {} });
  const [modalPlaceholderState, setModalPlaceholderState] = useState<ModalState>({ isModalOpen: false, modalStyle: {} });

  const boardType = useMemo(() => getData().boardType, [getData]);

  const placeholders = useMemo(() =>
    fabricManager ? new Placeholders(fabricManager) : undefined,
    [fabricManager]
  );

  const closeModals = useCallback(() => {
    console.trace('closeModals')
    setSelected(false);
    setCategoryModalState(prev => ({ ...prev, isModalOpen: false }));
    setModalPlaceholderState(prev => ({ ...prev, isModalOpen: false }));
    dispatch(setActiveModal(undefined));
  }, [dispatch])


  const openCategoryModal = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget;
    if (!target) return;

    const bounding = target.getBoundingClientRect();
    setCategoryModalState({
      isModalOpen: true,
      modalStyle: {
        left: `${bounding.left + bounding.width + 15}px`,
        width: 150,
      },
    });
    setSelected(true);
    dispatch(setActiveModal('addPlaceholder'));
  }, [dispatch])


  useEffect(() => {
    const clickListener = (event: MouseEvent) => {
      event.stopPropagation();
      const target = event.target as HTMLElement;
      if (!target) return;

      const isModalButton = target.closest('.modal-button');
      const isModal = target.closest('.base-modal');
      const isIconButton = target.closest('.icon-button');
      const isCategoryButton = target.closest('.category-button');

      if (isSelected && !isModalButton && !isModal && !isIconButton && !isCategoryButton) {
        closeModals();
      }
    };

    const closeModalOnEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isSelected) closeModals();
    };

    document.addEventListener('keydown', closeModalOnEsc);
    document.addEventListener('click', clickListener);

    return () => {
      document.removeEventListener('keydown', closeModalOnEsc);
      document.removeEventListener('click', clickListener);
    };
  }, [closeModals, isSelected]);

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (isSelected) {
      closeModals();
    } else {
      openCategoryModal(e);
    }
  }, [closeModals, isSelected, openCategoryModal])

  const addPlaceholder = (props: commonPlaceholder) => {
    if (!fabricManager || !placeholders) return;

    history.saveState();
    const placeholder = placeholders.common.create({ ...props, indicator: props.blockText });
    SnapToGrid.snapToGrid([placeholder]);
    closeModals();
  };

  const handleComplexPlaceholder = (placeholder: any) => {
    if (!fabricManager || !placeholders) return;

    history.saveState();
    placeholders.complex.create(placeholder);
    closeModals();
  };

  const handleEventPlaceholder = (placeholder: any) => {
    if (!fabricManager || !placeholders) return;

    history.saveState();
    const object = placeholders.event.create(placeholder);
    SnapToGrid.snapToGrid([object]);
    closeModals();
  };

  const handleCarouselPlaceholder = (placeholder: any) => {
    if (!fabricManager || !placeholders) return;

    history.saveState();
    const object = placeholders.carousel.create(placeholder);
    SnapToGrid.snapToGrid([object]);
    closeModals();
  };

  const openPlaceholderModal = (e: React.MouseEvent<HTMLButtonElement>, category: string) => {
    e.stopPropagation();
    const target = e.currentTarget;
    if (!target) return;

    const bounding = target.getBoundingClientRect();
    setModalPlaceholderState({
      isModalOpen: true,
      modalStyle: {
        top: `${bounding.top - 8}px`,
        left: `${bounding.left + bounding.width + 2}px`,
        width: 300,
      },
    });
    setSelectedCategory(category);
  };

  const renderPlaceholderButtons = () => {
    switch (selectedCategory) {
      case 'common':
        return commonPlaceholderList.map(({ listName, indicator, blockText, type, teamIndex }) => (
          <ModalButton
            className="modal-button"
            key={indicator}
            onClick={() => addPlaceholder({ indicator, blockText, type, teamIndex })}
          >
            {listName}
          </ModalButton>
        ));
      case 'complex':
        return complexPlaceholdersList.map(placeholder => (
          <ModalButton
            className="modal-button"
            key={placeholder.indicator}
            onClick={(e) => {
              e.stopPropagation();
              handleComplexPlaceholder(placeholder);
            }}
          >
            {placeholder.listName}
          </ModalButton>
        ));
      case 'events':
        return eventPlaceholderList.map(placeholder => (
          <ModalButton
            className="modal-button"
            key={placeholder.indicator}
            onClick={(e) => {
              e.stopPropagation();
              handleEventPlaceholder(placeholder);
            }}
          >
            {placeholder.listName}
          </ModalButton>
        ));
      case 'carousel':
        return carouselPlaceholderList.map(placeholder => (
          <ModalButton
            className="modal-button"
            key={placeholder.indicator}
            onClick={(e) => {
              e.stopPropagation();
              handleCarouselPlaceholder(placeholder);
            }}
          >
            {placeholder.listName}
          </ModalButton>
        ));
      default:
        return null;
    }
  };

  const filteredCategories = useMemo(() =>
    categoryList.filter(category => {
      const currentType = activeBoard?.type ?? boardType;
      if (currentType === 'events') return category.value !== 'carousel';
      if (currentType === 'carousel') return category.value !== 'events';
      return category.value !== 'events' && category.value !== 'carousel';
    }),
    [activeBoard?.type, boardType]
  );

  return (
    <>
      <UDTooltip show={!activeModal} css={tooltipStyles} text="Блоки">
        <EmptyButton
          className="icon-button"
          color={activeModal === 'addPlaceholder' ? '#F5D956' : undefined}
          onClick={handleClick}
          icon={<RectanglePlusIcon />}
        />
      </UDTooltip>

      <BaseModal className="base-modal" isOpen={categoryModalState.isModalOpen && isSelected} style={categoryModalState.modalStyle}>
        {filteredCategories.map(category => (
          <ModalButton
            className="category-button modal-button"
            key={category.value}
            onClick={(event) => openPlaceholderModal(event, category.value)}
            style={{ justifyContent: 'space-between' }}
          >
            {category.label}
            <ArrowBracketIcon color="#8F8F8F" width={10} height={10} direction="left" />
          </ModalButton>
        ))}
      </BaseModal>

      <BaseModal className="base-modal" isOpen={modalPlaceholderState.isModalOpen && isSelected} style={modalPlaceholderState.modalStyle}>
        {renderPlaceholderButtons()}
      </BaseModal>
    </>
  );
}
