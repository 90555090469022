import React from 'react';
import UDButton from "../../../ud-ui/components/button";
import UDText from "../../../ud-ui/components/text";

type Props = {
    locationName: string;
    onClose: () => void;
    onDelete: () => void;
    isLoading: boolean;
};

function DeleteLocationModal({ locationName, onClose, onDelete, isLoading }: Props) {
    return (
        <>
            <UDText
                type='headline'
                className='color-SurfaceRomanSilver30 px-10 mb-2'
                style={{ textAlign: 'center' }}
            >
                Удалить локацию {locationName}?
            </UDText>
            <UDText
                type='subhead'
                className='color-SurfaceRomanSilver30 px-10 mb-10'
                style={{ textAlign: 'center' }}
            >
                Это действие будет не обратимо.
            </UDText>
            <div className='flex-center w-100' style={{ gap: 10 }}>
                <UDButton
                    className='d-flex align-items-center justify-content-center mb-3 w-50'
                    style={{ width: 144 }}
                    variant='primary'
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                    disabled={isLoading}
                    loading={isLoading}
                >
                    <span>Да</span>
                </UDButton>
                <UDButton
                    className='d-flex align-items-center justify-content-center mb-3 w-50'
                    style={{ width: 144 }}
                    variant='primary'
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                    }}
                    disabled={isLoading}
                >
                    <span>Нет</span>
                </UDButton>
            </div>
        </>
    );
}

export default DeleteLocationModal;