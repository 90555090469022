import { Route, Routes } from 'react-router-dom';

import { Championship } from 'modules/tourneys/domain/interfaces/Championship';
import EditTeamForm from './edit-team-form';
import { Team } from 'modules/teams/domain/interfaces/Team';
import TeamsComponentsFormParticipantsListPart from '../form-parts/participants/participants-list';
import TeamsComponentsFormPlayersListPart from '../form-parts/players/players-list';
import WaitingList from '../form-parts/waiting-list/waiting-list';
import { getTeamPlayers } from 'modules/teams/store/players/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useEntryRequests } from '../../hooks/useEntryRequests';
import { useOrganizer } from '../../../../organizer/ui/hooks/useOrganizer';
import { usePlayers } from '../../../../organizer/ui/hooks/usePlayers';

type Props = {
  team: Team;
  onSubmit?: (data: Championship) => any;
};

const TeamComponentsEditForm = (props: Props) => {
  const dispatch = useDispatch();
  const { info, isLoading } = useOrganizer();
  const { team } = props;

  useEffect(() => {
    if (info && !isLoading && team) {
      dispatch(getTeamPlayers(team.id));
    }
  }, [dispatch, isLoading, info, team]);

  const { info: players, isGettingPlayers } = usePlayers();
  const { entryRequests, isLoading: isLoadingEntryRequests } = useEntryRequests(team.id);

  if (isLoading || isGettingPlayers || isLoadingEntryRequests) {
    return null;
  }


  return (
    <Routes>
      <Route
        path='players/*'
        element={
          <TeamsComponentsFormPlayersListPart
            tournamentId={info!.id}
            teamId={team.id}
            players={players.filter((player) => !player.isReal)}
          />
        }
      />
      <Route
        path='participants/*'
        element={
          <TeamsComponentsFormParticipantsListPart
            tournamentId={info!.id}
            teamId={team.id}
            participants={players.filter((player) => player.isReal)}
          />
        }
      />
      <Route
        path='waiting-list'
        element={
          <WaitingList
            tournamentId={info!.id}
            teamId={team.id}
            entryRequests={entryRequests.filter((entryRequest) => entryRequest.status === 'CREATED')}
          />
        }
      />
      {/* <Route
        path="admins"
        element={

          <TeamsComponentsFormAdminsListPart
            tournamentId={info!.id}
            teamId={team.id}
          />
        }
      /> */}
      {info && (
        <Route
          path='*'
          element={<EditTeamForm tournamentId={info.id} team={props.team} />}
        />
      )}
    </Routes>
  );
};

export default TeamComponentsEditForm;
