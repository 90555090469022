import { MainBlock } from './styles';

interface ListProps extends React.HTMLAttributes<HTMLDivElement> {

}

const UDList = (props: ListProps) => {
    const { children, ...rest } = props;

    return (
        <MainBlock {...rest}>
            {children}
        </MainBlock>
    );
}

export default UDList;
