import React, { useCallback, useMemo, useState } from 'react';
import { authSignIn, authSignUp } from 'modules/auth/store/actions';
import {
  authStoreCodeRequestedSelector,
  authStoreCodeRequestingSelector,
  authStoreErrorsSelector,
  authStoreLoadingSelector
} from 'modules/auth/store/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'store/store';
import AuthLayoutsMain from '../../layouts';
import { AuthSignInPayload } from '../../../domain/interfaces/AuthSignInPayload';
import { AuthSignUpPayload } from 'modules/auth/domain/interfaces/AuthSignUpPayload';
import { Formik } from 'formik';
import { Pages } from 'modules/navigation/domain/enums/pages';
import SignInForm from '../../components/signInForm';
import UDButton from 'modules/ud-ui/components/button';
import UDFormInput from 'modules/ud-form/components/input';
import UDInputPhone from 'modules/ud-form/components/phone-input';
import UDText from 'modules/ud-ui/components/text';
import { authSignInValidator } from '../../../domain/validators/auth-sign-in.validator';
import { authSignUpValidator } from 'modules/auth/domain/validators/auth-sign-up.validator';
import { useTranslation } from 'react-i18next';

type SignUpFormData = {
  firstName: string;
  lastName: string;
  middleName?: string;
  phone: string;
}

const AuthPagesSignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const isSignInLoading = useSelector(authStoreLoadingSelector);
  const stateErrors = useSelector(authStoreErrorsSelector);
  const isAuthCodeRequested = useSelector(authStoreCodeRequestedSelector);
  const isAuthCodeRequesting = useSelector(authStoreCodeRequestingSelector);

  const [username, setUsername] = useState('');

  const errors = useMemo(() => {
    if (!stateErrors) {
      return {};
    }

    return Object
      .keys(stateErrors as { [key: string]: string })
      .reduce((result, key) => ({
        ...result,
        [key]: t(`signUp.errors.${key}.${stateErrors[key]}`),
      }), {}) as SignUpFormData;
  }, [stateErrors, t]);

  const onSignUp = useCallback((data: AuthSignUpPayload) => {
    setUsername(data.phone);
    dispatch(authSignUp(data));
  }, [dispatch]);

  const onSignIn = useCallback((data: AuthSignInPayload) => {
    dispatch(authSignIn(data));
  }, [dispatch]);

  return (
    <AuthLayoutsMain
      title={t('signUp.title')}
      subtitle={t('signUp.subtitle')}
      action={{
        title: t('signUp.signInHint'),
        linkLabel: t('signUp.signInButtonLabel'),
        link: Pages.SIGN_IN,
      }}>
      {isAuthCodeRequested ? (
        <SignInForm
          initialValues={{ username, password: '' }}
          onSubmit={onSignIn}
          isLoading={isSignInLoading}
          validationSchema={authSignInValidator(t)}
        />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: '',
            lastName: '',
            middleName: '',
            phone: '',
          }}
          initialErrors={errors}
          onSubmit={onSignUp}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={authSignUpValidator(t)}>
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <UDFormInput
                name="lastName"
                label={t('signUp.nameInputLabel')}
                placeholder={t('signUp.lastNameLabelPlaceholder')}
                containerProps={{ className: 'mb-6' }}
              />
              <UDFormInput
                name="firstName"
                label=" "
                placeholder={t('signUp.firstNameLabelPlaceholder')}
                containerProps={{ className: 'mb-6' }}
              />
              <UDFormInput
                name="middleName"
                placeholder={t('signUp.middleNameLabelPlaceholder')}
                containerProps={{ className: 'mb-6' }}
              />
              <UDInputPhone
                name='phone'
                label={t('signUp.phoneInputLabel')}
                placeholder={t('signUp.phoneInputPlaceholder')}
                className='mb-6'
                value={formProps.values.phone}
              />
              <div className="d-flex flex-row align-items-center mb-4">
                <UDText type="caption-2" className="mr-2">
                  👆
                </UDText>
                <UDText type="caption-2" className="color-SurfaceRomanSilver30">
                  {t('signUp.agreementHint')}
                </UDText>
              </div>
              <UDButton
                variant="primary"
                type="submit"
                className="w-100"
                loading={isAuthCodeRequesting}
              >
                {t('signUp.signUnButtonLabel')}
              </UDButton>
            </form>
          )}
        </Formik>
      )}
    </AuthLayoutsMain>
  );
};

export default AuthPagesSignUp;
