import { Image, ImageValue } from '../types';
import { useCallback, useState } from 'react';

import { toast } from 'react-toastify';

interface UseImageManagementProps {
    name: string;
    multiple?: boolean;
    currentValue: any;
    setFieldValue: (field: string, value: any) => void;
    onChangeInterceptor: (value: any) => any;
}

export const useImageManagement = ({
    name,
    multiple,
    currentValue,
    setFieldValue,
    onChangeInterceptor
}: UseImageManagementProps) => {
    const [images, setImages] = useState<Array<Image | File>>(
        Array.isArray(currentValue) ? currentValue : currentValue ? [currentValue] : []
    );

    const getCurrentUrls = useCallback((value: any) => {
        return new Set(
            Array.isArray(value)
                ? value.map((img: ImageValue) =>
                    img instanceof File ? img.name : (typeof img === 'string' ? img : img.externalUrl)
                )
                : value
                    ? [value instanceof File
                        ? value.name
                        : (typeof value === 'string'
                            ? value
                            : value.externalUrl)]
                    : []
        );
    }, []);

    const handleFileChange = useCallback(
        (value: any) => {
            const files = value instanceof FileList ? Array.from(value) : value;

            handleInputInterceptor(onChangeInterceptor, files).then((processedFiles) => {
                const currentUrls = getCurrentUrls(currentValue);
                const newFiles = Array.isArray(processedFiles) ? processedFiles : [processedFiles];
                const uniqueFiles = newFiles.filter(file => !currentUrls.has(file.name));

                if (newFiles.length !== uniqueFiles.length) {
                    toast.warning('Некоторые изображения были пропущены, так как они уже добавлены');
                }

                if (multiple) {
                    const currentArray = Array.isArray(currentValue) ? currentValue : currentValue ? [currentValue] : [];
                    console.log({ currentArray, uniqueFiles });
                    setFieldValue(name, [...currentArray, ...uniqueFiles]);

                    setImages(prevImages => [
                        ...prevImages,
                        ...uniqueFiles
                    ]);
                } else if (uniqueFiles.length > 0) {
                    setFieldValue(name, [uniqueFiles[0]]);
                    setImages([uniqueFiles[0]]);
                }
            });
        },
        [name, setFieldValue, onChangeInterceptor, multiple, currentValue, getCurrentUrls]
    );


    const handleDelete = useCallback((index: number) => {
        setImages(prevImages => {
            const newImages = prevImages.filter((_, i) => i !== index);
            // Обновляем ordinalNumber после удаления
            const updatedImages = newImages.map((img, index) => {
                if (img instanceof File) {
                    return new File([img], img.name, { type: img.type });
                }
                return { ...img, ordinalNumber: index };
            });
            setFieldValue(name, updatedImages);
            return updatedImages;
        });
    }, [name, setFieldValue]);

    const handleDragEnd = useCallback((result: DragEndResult) => {
        const { source, destination } = result;

        if (!destination) return;

        setImages(prevImages => {
            const items = Array.from(prevImages);
            const [reorderedItem] = items.splice(source.index, 1);
            items.splice(destination.index, 0, reorderedItem);

            const updatedImages = items.map((img, index) => {
                if (img instanceof File) {
                    return new File([img], img.name, { type: img.type });
                }
                return { ...img, ordinalNumber: index };
            });


            console.log({ updatedImages, items, prevImages });
            setFieldValue(name, updatedImages);
            return updatedImages;
        });
    }, [name, setFieldValue]);

    return {
        images,
        handleFileChange,
        handleDelete,
        handleDragEnd
    };
};

async function handleInputInterceptor(interceptor: (value: any) => any, files: any) {
    return await interceptor(files);
}

interface DragEndResult {
    source: { index: number };
    destination: { index: number };
}
