import { CardContainer, TeamCardHeaderRow } from "../../../teams/ui/components/team-card/styles";
import React, { useCallback } from 'react';

import { AppDispatch } from "../../../../store/store";
import DeleteLocationModal from '../modals/delete-location-modal';
import EmptyButton from "../../../ud-ui/components/emptyButton";
import { Location } from "../../domain/interfaces/location";
import { LocationCardContainer } from './styles';
import LocationPhoto from './location-photo';
import { NewTrashIcon } from "../../../ud-ui/components/icon/new-trash-icon";
import { Pages } from "../../../navigation/domain/enums/pages";
import StadiumPlaceholderIcon from '../../../ud-ui/components/icon/stadium-placeholder-icon';
import UDButton from "../../../ud-ui/components/button";
import UDIcon from "../../../ud-ui/components/icon";
import UDListItem from "../../../ud-ui/components/list/components/list-item";
import UDText from "../../../ud-ui/components/text";
import classNames from "classnames";
import { deleteLocation } from "../../store/actions";
import { mapAddressToDadataArea } from "shared/features/dadata-address-autocomplete/infra/mappers/dadata-area.mapper";
import { selectIsProcessing } from "../../store/selectors";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import useModal from "../../../ud-ui/components/new-modal/useModal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

interface LocationCardProps {
    location: Location;
    index: number;
}

function LocationCard({ location, index }: LocationCardProps) {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { isProcessing } = useSelector(selectIsProcessing);

    // const { open: openDeleteModal, close: closeDeleteModal, Modal: DeleteModal } = useModal({ defaultShow: false });
    const { defaultOpen: openDeleteModal, close: closeDeleteModal, Modal: DeleteModal } = useModal();

    const handleClick = () => {
        navigate(Pages.LOCATIONS.builders.edit(location.id));
    };

    const handleDeleteLocation = async () => {
        try {
            await dispatch(deleteLocation({ id: location.id })).unwrap();
            closeDeleteModal();
        } catch (error) {
            console.error('Failed to delete location:', error);
            toast.error('Не удалось удалить локацию, возможно она используется в турнирах/матчах');
        }
    };

    const handleArchiveLocation = async () => {
        toast.warning('Функционал в разработке');
    };

    const onClickDelete = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        openDeleteModal();
    }, [openDeleteModal]);




    return (
        <CardContainer className='mb-4 last-mb-0'>

            <UDListItem
                style={{ cursor: 'pointer', backgroundColor: 'white' }}
                onClick={handleClick}
                showIndex
                avatar={location?.images[0]?.externalUrl ?? <StadiumPlaceholderIcon size={52} className="color-SurfaceRomanSilver30" />}
                index={index + 1}
                title={location.name}
                subtitle={location.address ? mapAddressToDadataArea(location.address).value : ''}
                additionalFields={[
                    <div key={location.id} className='flex-center' style={{ marginLeft: 'auto', paddingLeft: 10 }}>
                        {/* <UDButton key="archive" variant="secondary">В архив</UDButton> */}
                        <UDButton key="delete" variant="secondary" style={{ width: 144 }} onClick={onClickDelete}>Удалить</UDButton>
                    </div>
                ]}
            />
            {/* <LocationCardContainer onClick={handleClick}>
                <div className='location-card'>
                    <div className='row'>
                        <div className='location-column d-flex align-items-center justify-content-center' style={{ width: 'calc(100% / 24)' }}>
                            <div className="text body">{index + 1}</div>
                        </div>
                        <div className='location-column col-3 mr-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <div className="d-flex align-items-center">
                                <LocationPhoto
                                    images={location.images}
                                    name={location.name}
                                    className="mr-2"
                                />
                                <div className="text body">{location.name}</div>
                            </div>
                        </div>
                        <div className='location-column col-5 d-flex align-items-center'>
                            <div className="text body" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {location.address ? mapAddressToDadataArea(location.address).value : ''}
                            </div>
                        </div>
                        <div className='location-column col-3 d-flex align-items-center justify-content-center' style={{ marginLeft: 'auto' }}>
                            <UDButton
                                className='d-flex align-items-center mr-3'
                                variant='primary'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleArchiveLocation();
                                }}
                            >
                                <span>В архив</span>
                            </UDButton>

                            <UDButton
                                className='d-flex align-items-center'
                                variant='secondary'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openDeleteModal();
                                }}
                            >
                                <span>Удалить</span>
                            </UDButton>
                        </div>
                    </div>
                </div>
            </LocationCardContainer> */}

            <DeleteModal
            // header={{ title: '', subtitle: '' }}
            // containerProps={{
            //     style: {
            //         width: '500px',
            //         display: 'flex',
            //         flexDirection: 'column',
            //     },
            // }}
            >
                <DeleteLocationModal
                    locationName={location.name}
                    onClose={closeDeleteModal}
                    onDelete={handleDeleteLocation}
                    isLoading={isProcessing}
                />
            </DeleteModal>
        </CardContainer>
    );
}

export default LocationCard;