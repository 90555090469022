import styled from "@emotion/styled";

export const LocationFormContainer = styled.div`
  .location-form {
    --bs-gutter-x: 48px;
  }

  .location-data {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 16px;
  }

  .form-buttons {
    display: flex;
    justify-content: end;
    margin: 8px 0;
  }

  .col-6 {
    padding-right: 24px;

    &:last-child {
      padding-left: 24px;
      padding-right: 0;
    }
  }
`;

export const LocationCardContainer = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.SurfaceWhite};
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(26, 26, 41, 0.08), 0px 8px 16px rgba(26, 26, 41, 0.08);
  cursor: pointer;

  & .location-logo {
    flex-shrink: 0;
    object-fit: contain;
  }

  & .location-card {
    display: block;

    .location-column {
      display: flex;
      align-items: center;
    }
  }
`;