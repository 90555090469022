/** @jsxImportSource @emotion/react */

import { Field, FieldProps, useFormikContext } from 'formik';

import BackgroundLogo from "./component/BackgroundLogo";
import { ImageList } from './components/ImageList';
import React from 'react';
import UDDropZone from './component/UDDropZone';
import { UDFormDropZoneProps } from './types';
import UDFormLabel from '../label';
import classNames from 'classnames';
import { fileToImage } from './utils/fileToImage';
import { useImageManagement } from './hooks/useImageManagement';

const ACCEPTED_FORMATS = 'image/png,image/jpeg,image/jpg';

const UDFormDropZone: React.FC<UDFormDropZoneProps> = ({
  name,
  containerProps,
  label,
  labelProps,
  formProps,
  onChangeInterceptor = (value: Blob) => value,
  onDeleteImage,
  value,
  isCover,
  selectionBackground,
  multiple,
  enableDragAndDrop = false,
  ...otherDropZoneProps
}) => {
  const { setFieldValue, values } = useFormikContext<Record<string, any>>();
  const currentValue = values[name];

  const {
    images,
    handleFileChange,
    handleDelete,
    handleDragEnd
  } = useImageManagement({
    name,
    multiple,
    currentValue,
    setFieldValue,
    onChangeInterceptor
  });

  const renderContent = () => {
    if (selectionBackground) {
      return (
        <div className="mt-7 d-flex" style={{ position: 'relative' }}>
          <BackgroundLogo
            img={typeof value[0] === 'string' ? value[0] : value[0]?.url || value[0]?.externalUrl}
          />
        </div>
      );
    }

    return (
      <ImageList
        images={images.map(img => img instanceof File ? fileToImage(img) : img)}
        enableDragAndDrop={enableDragAndDrop}
        onDelete={handleDelete}
        onDeleteImage={onDeleteImage}
        isCover={isCover}
        multiple={multiple}
        onDragEnd={handleDragEnd}
      />
    );
  };

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps<any>) => (
        <div {...containerProps}>
          {label && (
            <UDFormLabel
              {...labelProps}
              error={meta.error}
              className={classNames('mb-2', labelProps?.className)}
            >
              {label}
            </UDFormLabel>
          )}
          <div {...formProps}>
            <UDDropZone
              accept={ACCEPTED_FORMATS}
              {...otherDropZoneProps}
              {...field}
              onChange={handleFileChange}
              multiple={multiple}
            />
            <span className="css-1jigk text footnote mt-2">
              Формат: png, jpg
            </span>
            {renderContent()}
          </div>
        </div>
      )}
    </Field>
  );
};

export default UDFormDropZone;
