import 'tippy.js/dist/tippy.css';

import { Field, FieldProps } from 'formik';

import React from 'react';
import Tippy from '@tippyjs/react';
import { UDFormComponentProps } from '../components/types';
import UDFormLabel from '../components/label';
import classNames from 'classnames';

type Props = UDFormComponentProps & {
  children: (fieldProps: FieldProps<any>) => JSX.Element;
};

const UDFormFieldWrapper = (props: Props) => {
  const { name, containerProps, labelProps, label, required, children } = props;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<any>) => {
        let errorText = ''
        if (typeof fieldProps.meta.error === 'string') {
          errorText = fieldProps.meta.error
        } else if (typeof fieldProps.meta.error === 'object') {
          errorText = Object.values(fieldProps.meta.error)[0] as string
        }

        return (
          <div {...containerProps}>
            {label && (
              <UDFormLabel
                {...labelProps}
                error={errorText}
                className={classNames('mb-2', labelProps?.className)}
              >
                {label}
              </UDFormLabel>
            )}
            <Tippy
              content={errorText}
              visible={
                !!required && !fieldProps.meta.value && fieldProps.meta.touched
              }
              placement='right'
              interactive={false}
            >
              {children(fieldProps)}
            </Tippy>
          </div>
        )
      }}
    </Field>
  );
};

export default UDFormFieldWrapper;
