import * as S from './styles';

import AuthComponentsBillboard from '../components/billboard';
import React from 'react';
import UDLink from 'modules/ud-ui/components/link';
import UDLogo from 'modules/ud-ui/components/logo';
import UDText from 'modules/ud-ui/components/text';
import classNames from 'classnames';

type Props = {
  title: string;
  subtitle: string;
  action?: {
    title: string;
    linkLabel: string;
    link: string;
  };
  children: React.ReactNode;
  contentClassName?: string;
};

const AuthLayoutsMain = (props: Props) => {
  return (
    <S.Container>
      <div className="left p-10 d-flex flex-column">
        <UDLogo className="mb-20 d-block" />
        <S.FormContainer>
          <div className="d-flex flex-row justify-content-between header mb-8">
            <div>
              <UDText type="title-1" className="mb-3 color-SurfaceWhite" style={{ fontFamily: 'SF Pro Text', fontWeight: 300 }}>
                {props.title}
              </UDText>
              <UDText
                type="callout"
                style={{ fontFamily: 'SF Pro Text', fontWeight: 300 }}
                className="subtitle opacity-6 color-SurfaceWhite">
                {props.subtitle}
              </UDText>
            </div>
          </div>
          <div className={classNames(props.contentClassName)}>{props.children}</div>
          {props.action && (
            <S.AlreadyContainer className="py-2 px-4">
              <UDText type="subhead" className="mb-2 color-SurfaceWhite">
                {props.action.title}
              </UDText>
              <UDLink
                to={props.action.link}
                className="color-AccentNapplesYellow">
                {props.action.linkLabel}
              </UDLink>
            </S.AlreadyContainer>
          )}
        </S.FormContainer>
        <div className="mt-auto">
          <a
            className="link text callout mr-6"
            href="https://imgameapp.ru/#organizer"
            target="_blank"
            rel="noreferrer"
          >
            О приложении
          </a>
          <a
            className="link text callout mr-6"
            href="https://imgameapp.ru/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Политика конфиденциальности
          </a>
          <a
            className="link text callout mr-6"
            href="https://imgameapp.ru/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
          >
            Условия использования
          </a>
        </div>
      </div>
      <AuthComponentsBillboard className="right" />
    </S.Container>
  );
};

export default AuthLayoutsMain;
