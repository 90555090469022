import { FetchLocations, FetchLocationsByFilter, UpsertLocationInput } from '../domain/interfaces/actions';
import { Image, Location } from '../domain/interfaces/location';

import { createAsyncThunk } from '@reduxjs/toolkit';
import locationsRepository from '../domain/repositories/locations.repository';
import locationsResource from '../domain/resources/locations.resource';

const PREFIX = 'locations';

const handleError = (error: unknown, prefix: string) => {
  console.error(`${prefix} error:`, error);
  const message = error instanceof Error ? error.message : String(error);
  return message;
};

export const fetchLocations = createAsyncThunk<Location[], Omit<FetchLocations, 'nameFilter'>>(
  `${PREFIX}/locations`,
  async (payload, { rejectWithValue }) => {
    try {
      return await locationsRepository.fetchLocations(payload.tournamentId, payload.limit, payload.offset);
    } catch (error) {
      return rejectWithValue(handleError(error, `${PREFIX}/fetchLocations`));
    }
  },
);

export const fetchLocationsByFilter = createAsyncThunk<Location[], FetchLocationsByFilter>(
  `${PREFIX}/fetchLocationsByFilter`,
  async (payload, { rejectWithValue }) => {
    try {
      return await locationsRepository.fetchLocations(
        payload.tournamentId,
        payload.limit,
        payload.offset,
        payload.nameFilter
      );
    } catch (error) {
      return rejectWithValue(handleError(error, `${PREFIX}/fetchLocationsByFilter`));
    }
  },
);


const deleteLocationImages = async (locationId: number, images: Image[]) => {
  if (images?.length) {
    await Promise.all(
      images.map(image => locationsResource.deleteImage(locationId, image.id))
    );
  }
};

export const deleteLocation = createAsyncThunk<{ id: number }, { id: number }>(
  `${PREFIX}/deleteLocation`,
  async (payload, { rejectWithValue }) => {
    try {
      const location = await locationsRepository.getLocation(payload.id);
      await deleteLocationImages(location.id, location.images);
      await locationsRepository.deleteLocation(payload.id);
      return { id: payload.id };
    } catch (error) {
      return rejectWithValue(handleError(error, `${PREFIX}/deleteLocation`));
    }
  }
);

export const upsertLocation = createAsyncThunk<Location, UpsertLocationInput>(
  `${PREFIX}/upsertLocation`,
  async (input, { rejectWithValue }) => {
    try {
      const { images = [], tournamentId, ...locationData } = input;
      let location = await locationsRepository.upsertLocation(
        { ...locationData, tournamentId },
        tournamentId
      );

      if (location.id) {
        location.images = await processLocationImages(location, images);
      }

      return location;
    } catch (error) {
      return rejectWithValue(handleError(error, `${PREFIX}/upsertLocation`));
    }
  }
);

interface UploadedImage {
  id: number;
  externalUrl: string;
  ordinalNumber: number;
  filename: string;
}

const processLocationImages = async (location: Location, images: (string | File)[]) => {
  // Разделяем изображения по типу
  const existingUrls = images.filter((img): img is string => typeof img === 'string');
  const newFiles = images.filter((img): img is File => img instanceof File);

  // Обработка изображений
  await deleteUnusedImages(location, existingUrls);
  const uploadedImages = await uploadNewImages(location.id, newFiles);
  await updateImagesOrder(location, existingUrls);

  // Формируем финальный результат
  return createFinalImageArray(images, location.images, uploadedImages);
};

const deleteUnusedImages = async (location: Location, existingUrls: string[]) => {
  const imagesToDelete = location.images.filter(
    img => !existingUrls.includes(img.externalUrl)
  );
  await deleteLocationImages(location.id, imagesToDelete);
};

const uploadNewImages = async (locationId: number, files: File[]): Promise<UploadedImage[]> => {
  if (!files.length) return [];

  return Promise.all(
    files.map(async (file, index) => {
      const result = await locationsResource.uploadImage(file, locationId, index);
      return {
        id: result.id,
        externalUrl: result.image,
        ordinalNumber: result.ordinalNumber,
        filename: file.name
      };
    })
  );
};

const updateImagesOrder = async (location: Location, existingUrls: string[]) => {
  const reorderedImages = existingUrls
    .map((url, index) => {
      const img = location.images.find(i => i.externalUrl === url);
      if (img && img.ordinalNumber !== index) {
        return { imageId: img.id, ordinalNumber: index };
      }
      return null;
    })
    .filter((item): item is { imageId: number; ordinalNumber: number } => item !== null);

  if (reorderedImages.length > 0) {
    await locationsResource.updateImagesOrder(location.id, reorderedImages);
  }
};

const createFinalImageArray = (
  originalImages: (string | File)[],
  existingImages: Image[],
  uploadedImages: UploadedImage[]
): Image[] => {
  return originalImages
    .map(img => {
      if (typeof img === 'string') {
        return existingImages.find(i => i.externalUrl === img);
      }
      return uploadedImages.find(i => i.filename === img.name);
    })
    .filter(Boolean) as Image[];
};
