import * as yup from 'yup';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../../../store/store';
import { ApplicationStatus } from '../../../../applications/domain/enums/ApplicationStatus';
import BorderedInput from '../bordered-input';
import { CreateUserInputDto } from '../../../domain/dtos/CreateUserInput.dto';
import { Formik } from 'formik';
import { FormikHelpers } from "formik/dist/types";
import { SuggestedPlayerList } from './create-team-player-form-fields';
import { TitleField } from "./styles";
import { TournamentUser } from '../../../domain/interfaces/TournamentUser';
import UDButton from '../../../../ud-ui/components/button';
import UDFormInput from '../../../../ud-form/components/input';
import UDText from "../../../../ud-ui/components/text";
import { authStoreUserTournamentId } from '../../../../auth/store/selectors';
import { birthDateFormatter } from '../../../../ud-form/components/input/formatters/birthDateFormatter';
import { format } from 'date-fns';
import playersRepository from '../../../domain/repositories/players.repository';
import styled from '@emotion/styled';
import { upsertChampionshipPlayerApplication } from '../../../store/championshipTeams/actions';
import { useTranslation } from 'react-i18next';

const CreateTeamPlayerFormWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const CreateTeamPlayerFormContainer = styled.div`
  padding: 0;
  border-radius: 8px;
  margin-bottom: 23px;
`;

const CreateTeamPlayerAutocompleteContainer = styled.div`
  position: relative;
`;

const CreateTeamPlayerFormFields = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 28px;
  gap: 16px;
`;

const teamPlayerValidator = yup
  .object()
  .shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    middleName: yup.string().optional(),
    birthDate: yup.string().matches(/^(\d{2})\.(\d{2})\.(\d{4})$/).required(),
  });

type CreateTeamPlayerFormProps = {
  teamId: number;
  championshipId: number;
  existPlayerIds: number[];
};

type TeamPlayerValues = {
  lastName: string;
  firstName: string;
  middleName: string;
  birthDate: string | null;
  time?: number,
};

const CreateTeamPlayerForm = ({ teamId, championshipId }: CreateTeamPlayerFormProps) => {
  const { t } = useTranslation();
  const tournamentId = useSelector(authStoreUserTournamentId);

  const [initialValues, setInitialValues] = useState<TeamPlayerValues>({
    firstName: '',
    lastName: '',
    middleName: '',
    birthDate: '',
  });

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const middleNameRef = useRef<HTMLInputElement>(null);
  const birthDateRef = useRef<HTMLInputElement>(null);
  const suggestionListRef = useRef<HTMLDivElement>(null);

  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuggestLoading, setIsSuggestLoading] = useState<boolean>(false);
  const [suggestQuery, setSuggestQuery] = useState<string>('');
  const [users, setUsers] = useState<TournamentUser[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [suggestionVisible, setSuggestionVisible] = useState<boolean>(true);

  const showLoadingIcon = useMemo(() => isLoading || isSuggestLoading, [isLoading, isSuggestLoading]);

  useEffect(() => {
    if (tournamentId && suggestQuery.length > 2) {
      setIsSuggestLoading(true);

      playersRepository
        .suggestTournamentPlayers(tournamentId, suggestQuery)
        .then((items) => setUsers(items))
        .finally(() => setIsSuggestLoading(false));
    } else if (suggestQuery.length <= 2 && users.length) {
      setUsers([]);
    }
  }, [suggestQuery, tournamentId]);

  const handleChange = useCallback(() => {
    const nameParts = [];

    if (lastNameRef.current) {
      nameParts.push(lastNameRef.current.value);
    }

    if (firstNameRef.current) {
      nameParts.push(firstNameRef.current.value);
    }

    if (middleNameRef.current) {
      nameParts.push(middleNameRef.current.value);
    }

    const name = nameParts
      .filter((value): value is string => Boolean(value))
      .map((item) => item.trim())
      .join(' ');

    setSelectedUserId(null);

    if (suggestQuery !== name) {
      setSuggestQuery(name);
    }
  }, [suggestQuery]);

  const handleFocus = useCallback(() => {
    setSuggestionVisible(true);
  }, []);

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (e.relatedTarget && suggestionListRef.current && e.relatedTarget === suggestionListRef.current) {
      return;
    }

    setSuggestionVisible(false);
  }, []);

  const handleSelectUser = useCallback((user: TournamentUser) => {
    const { id, surname: lastName, name: firstName } = user;
    const middleName = user.middleName || '';
    const birthDate = user.birthDate ? format(new Date(user.birthDate), 'dd.MM.uuuu') : user.birthDate;

    setUsers([]);
    setSelectedUserId(id);
    setInitialValues({
      lastName,
      firstName,
      middleName,
      birthDate,
      time: Date.now(),
    });
  }, []);

  const handleSubmit = useCallback((values: TeamPlayerValues, { resetForm }: FormikHelpers<TeamPlayerValues>) => {
    setIsLoading(true);

    const createUserOrUpdateApplication: CreateUserInputDto = {
      name: values.firstName,
      surname: values.lastName,
      middleName: values.middleName,
      birthDate: values.birthDate ?? undefined,
    };

    if (selectedUserId) {
      createUserOrUpdateApplication.userId = selectedUserId;
    }

    dispatch(upsertChampionshipPlayerApplication({
      teamId,
      championshipId,
      createUserOrUpdateApplication,
      state: ApplicationStatus.PENDING,
    })).then(() => {
      resetForm({
        values: {
          firstName: '',
          lastName: '',
          middleName: '',
          birthDate: '',
        },
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }, [championshipId, dispatch, selectedUserId, teamId]);

  return (
    <CreateTeamPlayerFormWrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={teamPlayerValidator}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form autoComplete='off' onSubmit={formProps.handleSubmit}>
            <CreateTeamPlayerFormContainer>
              <CreateTeamPlayerAutocompleteContainer>
                <CreateTeamPlayerFormFields>
                  <TitleField width={220}>
                    <UDText type={'subhead'} style={{ color: '#8F8E99' }}>Фамилия</UDText>
                    <UDFormInput
                      autoComplete='none'
                      name='lastName'
                      placeholder='Фамилия'
                      ref={lastNameRef}
                      inputComponent={() => <BorderedInput />}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={event => handleBlur(event)}
                    />
                  </TitleField>
                  <TitleField width={220}>
                    <UDText type={'subhead'} style={{ color: '#8F8E99' }}>Имя</UDText>
                    <UDFormInput
                      autoComplete='none'
                      name='firstName'
                      placeholder='Имя'
                      ref={firstNameRef}
                      inputComponent={() => <BorderedInput />}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      style={{ height: 36 }}
                    />
                  </TitleField>
                  <TitleField width={220}>
                    <UDText type={'subhead'} style={{ color: '#8F8E99' }}>Отчество</UDText>
                    <UDFormInput
                      autoComplete='none'
                      name='middleName'
                      placeholder='Отчество'
                      ref={middleNameRef}
                      inputComponent={() => <BorderedInput />}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      style={{ height: 36 }}
                    />
                  </TitleField>
                  <TitleField width={146}>
                    <UDText type={'subhead'} style={{ color: '#8F8E99' }}>Дата рождения</UDText>
                    <UDFormInput
                      name='birthDate'
                      placeholder='Дата рождения'
                      mask='дд.мм.гггг'
                      ref={birthDateRef}
                      formatter={birthDateFormatter}
                      inputComponent={() => <BorderedInput />}
                      style={{ height: 36 }}
                    />
                  </TitleField>
                  <UDButton
                    style={{ marginLeft: 3, width: 186, height: 36 }}
                    type='submit'
                    variant='primary'
                    disabled={showLoadingIcon}
                    loading={showLoadingIcon}
                  >{t('applications.addPlayerButtonTitle')}</UDButton>
                </CreateTeamPlayerFormFields>

                {suggestionVisible && (
                  <SuggestedPlayerList
                    ref={suggestionListRef}
                    users={users}
                    onClick={handleSelectUser}
                  />
                )}
              </CreateTeamPlayerAutocompleteContainer>
            </CreateTeamPlayerFormContainer>
          </form>
        )}
      </Formik>
    </CreateTeamPlayerFormWrapper>
  );
};

export default CreateTeamPlayerForm;
