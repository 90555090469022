import { RootState } from "../../../store/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectLocationsSlice = createSelector(
    (state: RootState) => state.locations,
    (state) => state,
);

export const selectLocationsPerPage = createSelector(
    [selectLocationsSlice],
    (state) => state.locationsPerPage,
);

export const selectLocations = createSelector(
    [selectLocationsSlice],
    (state) => state.locations,
);

export const selectLoad = createSelector(
    [selectLocationsSlice],
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    }),
);

export const selectLocationsByFilter = createSelector(
    [selectLocationsSlice],
    (state) => Boolean(state.filter.text) ? state.filter.locations : state.locations,
);

export const selectLocationsOnPage = createSelector(
    [selectLocationsSlice, selectLocationsByFilter],
    (state, locations) => {
        const isFilterActive = Boolean(state.filter.text)
        const currentPage = isFilterActive ? state.filter.currentPage : state.currentPage
        return locations.slice(
            (currentPage - 1) * state.locationsPerPage,
            (currentPage - 1) * state.locationsPerPage + state.locationsPerPage,
        )
    },
);

export const selectPages = createSelector(
    [selectLocationsSlice],
    (state) => state.filter.text ? state.filter.pages : state.pages,
);

export const selectCurrentPage = createSelector(
    [selectLocationsSlice],
    (state) => {
        return state.filter.text ? state.filter.currentPage : state.currentPage
    },
);

export const selectLocationById = (id: number) => createSelector(
    [selectLocationsOnPage],
    (locations) => locations.find((location) => location.id === id),
);

export const selectProcess = createSelector(
    [selectLocationsSlice],
    (state) => ({
        isProcessing: state.isProcessing,
        isProcessed: state.isProcessed,
        name: state.processName,
    }),
);

export const selectIsProcessing = createSelector(
    [selectProcess],
    (state) => ({
        isProcessing: state.isProcessing,
        isProcessed: state.isProcessed,
    }),
);
