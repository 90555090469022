import React, { forwardRef, useMemo } from 'react';
import { TeamEditFormPart, defaultTab } from './constants';
import { useLocation, useMatch } from 'react-router-dom';

import { Pages } from "../../../../navigation/domain/enums/pages";
import { Tab } from "../../../../ud-ui/components/tabs/interfaces";
import UDTabs from 'modules/ud-ui/components/tabs';
import UDText from '../../../../ud-ui/components/text';
import classNames from 'classnames';

type CreateEditFormParams = {
  tab: Tab;
  count?: number;
  isActive?: boolean;
  subLabel?: string;
  state?: any;
};

const createEditFormTab = (params: CreateEditFormParams): Tab => {
  const { tab, count, isActive, subLabel, state } = params;

  if (count !== undefined) {
    const badgeBgColor = isActive
      ? 'bgc-SurfaceWhite'
      : 'bgc-AccentNapplesYellow';
    const className = classNames([
      'color-SurfaceRaisinBlack50',
      badgeBgColor,
      'p-1',
      'br-4',
      'ml-2',
      'line-height-1',
      'caption-1',
    ]);

    return {
      hash: tab.hash,
      label: (
        <>
          {tab.label}{' '}
          <UDText type='subhead' className={className}>
            {count}
          </UDText>
        </>
      ),
    };
  }

  return { ...tab, subLabel, state };
};

type TeamEditFormTabsProps = {
  state?: any
};

const TeamEditFormTabs = forwardRef<HTMLDivElement, TeamEditFormTabsProps>(({ state }, ref) => {
  const location = useLocation();
  const activeTab = (location.hash || '').split('/')[0];
  const createNewPlayer = useMatch(`${Pages.TEAMS.EDIT}/players/create`);

  const tabs = useMemo(() => {
    return Object.values(TeamEditFormPart).map((tab) =>
      createEditFormTab({
        tab: tab,
        isActive: tab.hash === activeTab,
        subLabel: tab.hash === TeamEditFormPart.PLAYERS.hash && createNewPlayer ? 'Создание игрока' : undefined,
        state: state,
      }),
    );
  }, [activeTab, createNewPlayer, state]);

  return <UDTabs tabs={tabs} defaultTab={defaultTab} ref={ref} />;
});

export default TeamEditFormTabs;
