import { CardContainer, TeamCardHeaderRow } from '../../../teams/ui/components/team-card/styles';

import React from 'react';

interface LocationSkeletonProps {
    className?: string;
}

export const LocationSkeleton: React.FC<LocationSkeletonProps> = ({ className }) => {
    return (
        <CardContainer className={`mb-4 last-mb-0 ${className || ''}`}>
            <TeamCardHeaderRow className="skeleton-block">
                <div className='team-card'>
                    <div className='row'>
                        <div className='team-column col-1'>
                            <div className="skeleton-box" style={{ width: '30px', height: '24px' }} />
                        </div>
                        <div className='team-column col-3'>
                            <div className="d-flex align-items-center">
                                <div className="skeleton-box mr-2" style={{ width: '52px', height: '52px', borderRadius: '4px' }} />
                                <div className="skeleton-box" style={{ width: '150px', height: '24px' }} />
                            </div>
                        </div>
                        <div className='team-column col-4'>
                            <div className="skeleton-box" style={{ width: '200px', height: '24px' }} />
                        </div>
                        <div className='team-column col-3'>
                            <div className="skeleton-box" style={{ width: '150px', height: '24px' }} />
                        </div>
                    </div>
                </div>
            </TeamCardHeaderRow>
        </CardContainer>
    );
}; 