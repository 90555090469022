import React, { useCallback } from 'react';
import UDFormSelect, { UDFormAddressSelect } from '../../../../ud-form/components/select';
import {
  mapAddressToDadataArea,
  mapDadataAreaToAddress
} from '../../../../../shared/features/dadata-address-autocomplete/infra/mappers/dadata-area.mapper';

import { Formik } from 'formik';
import { OrganizerUpsertPayload } from '../../../domain/interfaces/OrganizerUpsertPayload';
import { SportType } from '../../../../core/domain/enums/SportType';
import { TFunction } from "i18next";
import UDButton from '../../../../ud-ui/components/button';
import UDFormDropZone from '../../../../ud-form/components/dropzone';
import UDFormInput from '../../../../ud-form/components/input';
import UDFormTextearea from '../../../../ud-form/components/input/textarea';
import UDInputPhone from 'modules/ud-form/components/phone-input';
import { isEmptyAddress } from '../../../../core/helpers/isEmptyAddress';
import { useTranslation } from 'react-i18next';

const sportTypeToSelectOption = (sportType: string, t: TFunction) => {
  const sportTypeKey = sportType.toLowerCase();
  return {
    label: t(`sportType.${sportTypeKey}`),
    value: sportTypeKey,
  };
};

const sportTypesToSelectOptions = (t: TFunction) =>
  Object.keys(SportType).map((sportType) =>
    sportTypeToSelectOption(sportType, t)
  );

type OrganizerFormProps = {
  isLoading?: boolean;
  initialValues?: OrganizerUpsertPayload;
  onSubmit: (values: OrganizerUpsertPayload) => void;
};

export const OrganizerForm = ({ isLoading, initialValues, onSubmit }: OrganizerFormProps) => {
  const { t } = useTranslation();
  const sportTypeOptions = sportTypesToSelectOptions(t);
  const logoUrl = initialValues?.logoUrl;

  const initialFormValues = initialValues
    ? {
      ...initialValues,
      address: isEmptyAddress(initialValues.address) ? undefined : mapAddressToDadataArea(initialValues.address!),
      sportType: initialValues
        ? sportTypeToSelectOption(initialValues.sportType, t)
        : sportTypeToSelectOption(SportType.football, t),
    }
    : {
      name: '',
      sportType: sportTypeToSelectOption(SportType.football, t),
      phone: '',
    };

  const handleSubmit = useCallback((values: any) => {
    const { address: changedAddress } = values;
    const address = changedAddress ? mapDadataAreaToAddress(changedAddress) : changedAddress;

    const data = {
      ...values,
      address,
      sportType: values.sportType.value,
    };

    onSubmit(data);
  }, [onSubmit]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <div className="row g-2">
            <div className="col-6">
              <UDFormInput
                disabled={isLoading}
                name="name"
                label="Название огранизации"
                placeholder="Введите название"
                containerProps={{ className: 'mb-5' }}
              />

              <UDFormSelect
                isDisabled={isLoading}
                name="sportType"
                label="Тип спорта"
                placeholder="Выберите тип спорта"
                isMulti={false}
                options={sportTypeOptions}
                containerProps={{ className: 'mb-5' }}
              />

              <UDFormInput
                disabled={isLoading}
                name="email"
                label="Email"
                placeholder="Email"
                containerProps={{ className: 'mb-5' }}
              />

              {/* TODO: Add phone input */}
              {/* <UDFormInput
                disabled={isLoading}
                name="phone"
                label="Телефон"
                placeholder="+7"
                containerProps={{ className: 'mb-5' }}
                formatter={formatPhone}
                parser={parsePhone}
              /> */}
              <UDInputPhone
                name='phone'
                label="Телефон"
                placeholder="+7"
                className='mb-6'
                value={formProps.values.phone}
              />

              <UDFormAddressSelect
                name="address"
                label="Город"
                placeholder={t('championship.info.addressInput.placeholder')}
                containerProps={{ className: 'mb-5' }}
                onlyCity={true}
              />

              <UDFormTextearea
                disabled={isLoading}
                name="description"
                label="Описание"
                placeholder="Введите текст"
                containerProps={{ className: 'mb-5' }}
              />
            </div>
            <div className="col-1"></div>
            <div className="col-5">
              <UDFormDropZone
                disabled={isLoading}
                name="logo"
                label="Обложка и изображения"
                value={logoUrl ? [logoUrl] : []}
                isCover={true}
                formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
                selectionBackground
              />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-6 mb-2">
            <UDButton
              variant="primary"
              type="submit"
              className="mb-3"
              disabled={isLoading}
              loading={isLoading}
            >
              Сохранить
            </UDButton>
          </div>
        </form>
      )}
    </Formik>
  );
};
