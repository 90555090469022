import React, { useCallback, useEffect, useState } from 'react';

import BackgroundSelection from "./components/BackgroundSelection";
import { DivProps } from "../../../../../../typings";
import LogoView from "./components/LogoView";
import { MainBlock } from "./styles";
import { SyncLoader } from "react-spinners";
import _ from "lodash";
import supabase from "../../../../../core/supabase/supabase";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";

type IProps = DivProps & {
  img?: string
}

function BackgroundLogo(props: IProps) {
  const { img, ...rest } = props
  const [isEdit, setIsEdit] = useState(false)
  const [currentBackground, setCurrentBackground] = useState<background>()
  const { info, isLoaded } = useOrganizer()

  useEffect(() => {
    if (!info) return
    supabase.organizerLogoBacking.get({ tournamentId: info.id }).then((row) => {
      if (!row) {
        setCurrentBackground(Colors[0])
        return;
      }
      setCurrentBackground(Colors[row.type])
    })
  }, [isLoaded, info])

  const onSubmit = useCallback((background: background) => {
    if (!info) {
      setIsEdit(false);
      return;
    }

    const type = parseInt(_.findKey(Colors, background) ?? '')
    if (isNaN(type)) {
      setIsEdit(false);
      return;
    }

    supabase.organizerLogoBacking.update({ tournamentId: info.id, type }).then((result) => {
      if (result) setCurrentBackground(background)
      setIsEdit(false)
    })
  }, [info]);

  const onCancel = () => {
    setIsEdit(false)
  }

  console.log({ img, currentBackground })

  return (
    currentBackground ? (
      <MainBlock {...rest}>
        {isEdit
          ? <BackgroundSelection
            onSubmit={onSubmit}
            onCancel={onCancel}
            backgrounds={backgrounds} img={img} />
          : <LogoView
            onClick={() => setIsEdit(true)}
            colorName={currentBackground.name}
            color={currentBackground.value}
            img={img} />
        }
      </MainBlock>
    ) : (
      <SyncLoader />
    )
  );
}

export default BackgroundLogo;

type background = { name: string, value: string }

const Colors: Record<number, background> = {
  0: { name: 'Прозрачный', value: 'transparent' },
  1: { name: 'Синий', value: '#1A1A29' },
  2: { name: 'Белый', value: '#FFFFFF' },
}

const backgrounds: background[] = [
  { name: 'Прозрачный', value: 'transparent' },
  { name: 'Синий', value: '#1A1A29' },
  { name: 'Белый', value: '#FFFFFF' },
]
