/** @jsxImportSource @emotion/react */

import React, { useCallback, useMemo, useState } from 'react';
import { UDButton, UDFilter, UDList, UDListItem, UDText } from 'modules/ud-ui/components';
import { changePlayerRole, deleteTournamentPlayerApplication } from '../../../../store/players/actions';

import { AppDispatch } from '../../../../../../store/store';
import { ParticipantsList } from './styles';
import { Role } from 'shared/types/role';
import { TournamentUser } from '../../../../domain/interfaces/TournamentUser';
import { UDSelect } from 'modules/ud-form/components';
import { css } from '@emotion/react';
import defaultAvatar from 'shared/assets/images/default-avatar';
import getBirthDay from 'shared/utils/getBirthDay';
import { getFullName } from 'shared/utils/getFullName';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Props {
    tournamentId: number;
    teamId: number;
    participants?: TournamentUser[];
}

const TeamsComponentsFormParticipantsListPart: React.FC<Props> = ({
    tournamentId,
    teamId,
    participants = []
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [filterValue, setFilterValue] = useState('');

    const changeRole = useCallback((participant: TournamentUser, role: string) => {
        try {
            dispatch(changePlayerRole({ teamId, userId: participant.id, role: role as Role })).unwrap()
                .then(() => {
                    toast.success(t('participant.changeRole', { participant: getFullName(participant), role: t(`player.role.${role}`) }));
                }).catch((error) => {
                    toast.error(t('participant.changeRoleError', { participant: getFullName(participant) }));
                });
        } catch (error) {
            toast.error(t('participant.changeRoleError', { participant: getFullName(participant) }));
        }

    }, [dispatch, t, teamId]);

    const kickFromTeam = useCallback((participant: TournamentUser) => {
        try {
            dispatch(deleteTournamentPlayerApplication({ teamId, userId: participant.id })).unwrap()
                .then(() => {
                    toast.success(t('participant.kickFromTeam', { participant: getFullName(participant) }));
                }).catch((error) => {
                    toast.error(t('participant.kickFromTeamError', { participant: getFullName(participant) }));
                });
        } catch (error) {
            toast.error(t('participant.kickFromTeamError', { participant: getFullName(participant) }));
        }
    }, [dispatch, t, teamId]);

    const participantsByFilter = useMemo(() => {
        return participants.filter((participant) => {
            return getFullName(participant).toLowerCase().includes(filterValue.toLowerCase());
        });
    }, [participants, filterValue]);

    return (
        <ParticipantsList>
            <UDFilter
                inputPlaceholder={t('participant.filter.inputPlaceholder')}
                value={filterValue}
                onChange={setFilterValue}
                style={{ marginBottom: 10 }}
            />

            <UDList>
                {participantsByFilter.length > 0 ? participantsByFilter.map((participant, index) => (
                    <UDListItem
                        key={participant.id}
                        index={index + 1}
                        avatar={participant.photo?.externalUrl ?? defaultAvatar}
                        title={getFullName(participant)}
                        subtitle={`${getBirthDay(participant?.birthDate ? new Date(participant.birthDate) : undefined)}`}
                        showIndex
                        additionalFields={[
                            <div key={participant.id} className='flex-center' style={{ marginLeft: 'auto', paddingLeft: 10 }}>

                                <UDSelect
                                    divStyle={{ width: 144, marginRight: 30 }}
                                    options={Roles.map((role) => ({
                                        label: t(`player.role.${role}`),
                                        value: role,
                                    }))}
                                    value={{ label: t(`player.role.${participant.role}`), value: participant.role }}
                                    onChange={(value) => changeRole(participant, value?.value ?? '')}
                                />

                                <UDButton
                                    style={{ width: 144 }}
                                    variant='secondary'
                                    onClick={() => kickFromTeam(participant)}
                                >
                                    {t('participant.remove')}
                                </UDButton>

                            </div>
                        ]}
                    />
                )) : (
                    <div css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                    `}>
                        <UDText
                            type='subhead'
                            className='bold'
                            color='#8F8E99'
                            style={{ fontWeight: 400, fontSize: 16 }}
                        >
                            {t('participant.empty')}
                        </UDText>
                    </div>
                )}
            </UDList>
        </ParticipantsList>
    );
};


export default TeamsComponentsFormParticipantsListPart;

const Roles = [
    'STAFF',
    'CAPTAIN',
    'FAN',
]
