import { Route, Routes } from 'react-router-dom';

import AuthPagesSelectTournament from 'modules/auth/ui/pages/select-tournament/select-tournament';
import AuthPagesSignIn from 'modules/auth/ui/pages/sign-in';
import AuthPagesSignUp from 'modules/auth/ui/pages/sign-up';
import BoardEditor from '../template-editor/ui/pages/board-editor';
import BoardPage from "../board-master/ui/pages/board/board-page";
import BoardPreview from "../template-editor/ui/pages/board-preview";
import Boards from "../template-editor/ui/pages/boards";
import Calendar from "../shedule/ui/pages/calendar";
import Catalog from "../template-editor/ui/pages/catalog";
import CatalogCard from "../template-editor/ui/pages/catalogCard";
import FullSizeControlPanel from "../stream/ui/page/fullSizeControlPanel";
import LocationCreatePage from "../locations/ui/pages/location-create-page";
import LocationViewPage from "../locations/ui/pages/location-view-page";
import LocationsListPage from "../locations/ui/pages/locations-list-pages";
import MainPagesIndex from 'modules/main/ui/pages/index';
import MasterBoardPage from "../board-master/ui/pages/main/master-board-page";
import OrganizerCard from "../organizers/ui/pages/ogranizer-card";
import OrganizersList from "../organizers/ui/pages/organizers-list";
import Overlay from '../stream/ui/page/overlay';
import { Pages } from './domain/enums/pages';
import PanelPage from "../board-master/ui/pages/panel/panel-page";
import PlayerCreatePage from "../players/ui/pages/player-create-page";
import PlayerListPage from "../players/ui/pages/player-list-page";
import PlayerViewPage from "../players/ui/pages/player-view-page";
import PreviewTemplate from "../shedule/ui/pages/template-preview";
import { QueryParamProvider } from 'use-query-params';
import RouteAdapter from './utils/route-adapter';
import SecureRoute from './ui/components/secure-route';
import SettingsPage from '../main/ui/pages/settings-page';
import TeamsCreatePage from '../teams/ui/pages/teams-create-page';
import TeamsEditPage from '../teams/ui/pages/team-edit-page';
import TeamsPage from '../teams/ui/pages/teams-page';
import Templates from "../template-editor/ui/pages/templates";
import TourneyPagesCreatePage from 'modules/tourneys/ui/pages/create';
import TourneyPagesEditPage from 'modules/tourneys/ui/pages/edit';

const AppRouter = () => {
  return (
    <QueryParamProvider ReactRouterRoute={RouteAdapter}>
      <Routes>
        <Route
          path={Pages.INDEX}
          element={
            <SecureRoute>
              <MainPagesIndex />
            </SecureRoute>
          }
        />
        <Route
          path={Pages.SETTINGS}
          element={
            <SecureRoute tournamentRequired={false}>
              <SettingsPage />
            </SecureRoute>
          }
        />
        <Route path={Pages.SIGN_UP} element={<AuthPagesSignUp />} />
        <Route path={Pages.SIGN_IN} element={<AuthPagesSignIn />} />
        <Route
          path={Pages.SELECT_TOURNAMENT}
          element={<AuthPagesSelectTournament />}
        />
        <Route
          path={Pages.TOURNEYS.INDEX}
          element={
            <SecureRoute>
              <MainPagesIndex />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.TOURNEYS.CREATE}`}
          element={
            <SecureRoute>
              <TourneyPagesCreatePage />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.TOURNEYS.EDIT}/*`}
          element={
            <SecureRoute>
              <TourneyPagesEditPage />
            </SecureRoute>
          }
        >
          {/*<Route path={Pages.TOURNEYS.INDEX} element={<SecureRoute><MainPagesIndex /></SecureRoute>} />*/}
        </Route>

        <Route
          path={Pages.TEAMS.INDEX}
          element={
            <SecureRoute>
              <TeamsPage />
            </SecureRoute>
          }
        />
        <Route
          path={Pages.TEAMS.CREATE}
          element={
            <SecureRoute>
              <TeamsCreatePage />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.TEAMS.EDIT}/*`}
          element={
            <SecureRoute>
              <TeamsEditPage />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.TEMPLATE.INDEX}/*`}
          element={
            <SecureRoute>
              <Templates />
            </SecureRoute>
          }
        /> <Route
          path={`${Pages.TEMPLATE.BOARDS}/*`}
          element={
            <SecureRoute>
              <Boards />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.TEMPLATE.EDITOR}/*`}
          element={
            <SecureRoute>
              <BoardEditor />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.TEMPLATE.PREVIEW}/*`}
          element={
            <SecureRoute>
              <BoardPreview />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.TEMPLATE.CATALOG.INDEX}/*`}
          element={
            <SecureRoute>
              <Catalog />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.TEMPLATE.CATALOG.CARD}/*`}
          element={
            <SecureRoute>
              <CatalogCard />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.OVERLAY.INDEX}/*`}
          element={
            <Overlay />
          }
        />
        <Route
          path={`${Pages.OVERLAY.CONTROL_PANEL}/*`}
          element={
            <FullSizeControlPanel />
          }
        />
        <Route
          path={`${Pages.ORGANIZERS.INDEX}`}
          element={
            <SecureRoute>
              <OrganizersList />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.ORGANIZERS.VIEW}`}
          element={
            <SecureRoute>
              <OrganizerCard />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.ORGANIZERS.INDEX}`}
          element={
            <SecureRoute>
              <OrganizersList />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.ORGANIZERS.VIEW}`}
          element={
            <SecureRoute>
              <OrganizerCard />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.SCHEDULE.INDEX}`}
          element={
            <SecureRoute>
              <Calendar />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.SCHEDULE.VIEW}`}
          element={
            <SecureRoute>
              <PreviewTemplate />
            </SecureRoute>
          }
        />
        <Route
          path={Pages.PLAYERS.INDEX}
          element={
            <SecureRoute>
              <PlayerListPage />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.PLAYERS.VIEW}/*`}
          element={
            <SecureRoute>
              <PlayerViewPage />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.PLAYERS.CREATE}/*`}
          element={
            <SecureRoute>
              <PlayerCreatePage />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.MASTER_BOARDS.INDEX}`}
          element={
            <MasterBoardPage />
          }
        />
        <Route
          path={`${Pages.MASTER_BOARDS.CONTROL_PANEL}`}
          element={
            <PanelPage />
          }
        />
        <Route
          path={`${Pages.MASTER_BOARDS.BOARD}`}
          element={
            <BoardPage />
          }
        />
        <Route
          path={Pages.LOCATIONS.INDEX}
          element={
            <SecureRoute>
              <LocationsListPage />
            </SecureRoute>
          }
        />
        <Route
          path={Pages.LOCATIONS.CREATE}
          element={
            <SecureRoute>
              <LocationCreatePage />
            </SecureRoute>
          }
        />
        <Route
          path={`${Pages.LOCATIONS.VIEW}/*`}
          element={
            <SecureRoute>
              <LocationViewPage />
            </SecureRoute>
          }
        />
      </Routes>
    </QueryParamProvider>
  );
};
export default AppRouter;
