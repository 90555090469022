export type ValidPhoneCode = '+7' | '+375' | '+374';

export const formatPhone = (value = '', code?: ValidPhoneCode): string => {
  const normalizedPhone = value.replace(/[^0-9]/g, '');

  let codeToUse = code;
  if (!code) {
    codeToUse = normalizedPhone.startsWith('+7') ? '+7' : normalizedPhone.startsWith('+375') ? '+375' : '+374';
  };

  let regexPhone: RegExp | undefined;

  switch (codeToUse) {
    case '+7': {
      // Russia and Kazakhstan
      regexPhone = /^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/;
      break;
    }
    case '+375': {
      // Belarus
      // +375 (33) 302-66-60
      regexPhone = /^(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})$/;
      break;
    }
    case '+374': {
      // Armenia
      // +374 (51) 123-456
      regexPhone = /^(\d{0,2})(\d{0,3})(\d{0,3})$/;
      break;
    }
  }

  if (!regexPhone) return normalizedPhone;

  return normalizedPhone.replace(regexPhone, (_, ...parts) => {
    const results = [];

    if (parts[ 0 ]) {
      results.push(' (', parts[ 0 ]);
    }

    if (parts[ 1 ]) {
      results.push(') ', parts[ 1 ]);
    }

    if (parts[ 2 ]) {
      results.push('-', parts[ 2 ]);
    }

    if (parts[ 3 ]) {
      results.push('-', parts[ 3 ]);
    }

    return results.join('');
  });
};
