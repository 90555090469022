import { Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const scrollbar = (theme: Theme) => css`
    &::-webkit-scrollbar {
    width: 4px;
    background-color: #2D2D47;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.AccentNapplesYellow};
  }
`;
