/** @jsxImportSource @emotion/react */

import { UDButton, UDFilter, UDList, UDListItem } from 'modules/ud-ui/components';
import { useCallback, useMemo, useState } from 'react';

import { AppDispatch } from '../../../../../../store/store';
import { EntryRequest } from "modules/teams/domain/interfaces/EntryRequest";
import { EntryRequestStatus } from 'shared/types/entryRequestStatus';
import { ParticipantsList } from '../participants/styles';
import { UDText } from 'modules/ud-ui/components';
import { css } from '@emotion/react';
import defaultAvatar from 'shared/assets/images/default-avatar';
import getBirthDay from 'shared/utils/getBirthDay';
import { getFullName } from 'shared/utils/getFullName';
import { theme } from 'styles/theme/theme';
import { toast } from 'react-toastify';
import { updateEntryRequest } from '../../../../store/entryRequests/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface WaitingListProps {
    tournamentId: number;
    teamId: number;
    entryRequests: EntryRequest[];
}

const WaitingList = ({ tournamentId, teamId, entryRequests }: WaitingListProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [filterValue, setFilterValue] = useState('');

    const changeEntryRequestStatus = useCallback((entryRequest: EntryRequest, status: EntryRequestStatus) => {
        try {
            dispatch(updateEntryRequest({ entryRequestId: entryRequest.requestId, status })).unwrap().then(() => {
                toast.success(t('entryRequest.changeStatus', { entryRequest: getFullName(entryRequest), status: t(`entryRequest.status.${status}`) }));
            }).catch((error) => {
                toast.error(t('entryRequest.changeStatusError', { entryRequest: getFullName(entryRequest) }));
            });
        } catch (error) {
            toast.error(t('entryRequest.changeStatusError', { entryRequest: getFullName(entryRequest) }));
        }

    }, [t, dispatch]);

    const entryRequestsByFilter = useMemo(() => {
        return entryRequests.filter((entryRequest) => {
            return entryRequest.name.toLowerCase().includes(filterValue.toLowerCase());
        });
    }, [entryRequests, filterValue]);


    return (
        <ParticipantsList>
            <UDFilter
                inputPlaceholder={t('participant.filter.inputPlaceholder')}
                value={filterValue}
                onChange={setFilterValue}
                style={{ marginBottom: 10 }}
            />
            <UDList>
                {entryRequestsByFilter.length > 0 ? entryRequestsByFilter.map((entryRequest, index) => (
                    <UDListItem
                        key={entryRequest.id}
                        index={index + 1}
                        avatar={entryRequest.photo?.externalUrl ?? defaultAvatar}
                        title={getFullName(entryRequest)}
                        subtitle={`${getBirthDay(entryRequest?.birthDate ? new Date(entryRequest.birthDate) : undefined)}`}
                        showIndex
                        additionalFields={[
                            <div key={entryRequest.id} className='flex-center' style={{ marginLeft: 'auto', paddingLeft: 10 }}>
                                <UDButton
                                    style={{ width: 144, marginRight: 30, outlineColor: theme.colors.Green }}
                                    variant='secondary'
                                    onClick={() => changeEntryRequestStatus(entryRequest, 'ACCEPTED')}
                                >
                                    {t('entryRequest.status.ACCEPTED')}
                                </UDButton>

                                <UDButton
                                    style={{ width: 144, outlineColor: theme.colors.Pink }}
                                    variant='secondary'
                                    onClick={() => changeEntryRequestStatus(entryRequest, 'DECLINED')}
                                >
                                    {t('entryRequest.status.DECLINED')}
                                </UDButton>
                            </div>
                        ]}
                    />
                )) : (
                    <div css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                    `}>
                        <UDText
                            color='#8F8E99'
                            type='subhead'
                            className='bold'
                            style={{ fontWeight: 400, fontSize: 16 }}
                        >
                            {t('entryRequest.waitingList.empty')}.
                        </UDText>
                    </div>
                )}
            </UDList>
        </ParticipantsList>
    );
};





export default WaitingList;
