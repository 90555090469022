import { selectCurrentPage, selectLoad, selectLocationsOnPage, selectLocationsPerPage, selectPages, selectProcess } from '../../store/selectors';

import EmptyState from './empty-state';
import LocationCard from './location-card';
import { LocationSkeleton } from './location-skeleton';
import UDPagination from '../../../ud-ui/components/pagination';
import { useSelector } from 'react-redux';

type Props = {
  onPageChange: (page: number) => void;
};

function LocationList({ onPageChange }: Props) {
  const locations = useSelector(selectLocationsOnPage);
  const pages = useSelector(selectPages);
  const currentPage = useSelector(selectCurrentPage);
  const isLoading = useSelector(selectProcess);
  const isLoaded = useSelector(selectLoad);
  const perPage = useSelector(selectLocationsPerPage);


  if (isLoading && locations.length === 0) {
    return (
      <div className='container pt-6'>
        {[...Array(perPage)].map((_, index) => (
          <LocationSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (isLoaded && locations.length === 0) {
    return <EmptyState />;
  }

  return (
    <div className='container pt-6'>
      {locations.map((location, index) => (
        <LocationCard
          key={location.id}
          location={location}
          index={index}
        />
      ))}

      <UDPagination
        className='justify-content-end px-6 my-8'
        pages={pages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
}

export default LocationList;