import AsyncSelect, { AsyncProps } from 'react-select/async';
import { DadataAreaType, IDadataArea } from '../../domain';

import { GroupBase } from 'react-select';
import React from 'react';
import { createDadataRepository } from '../../infra/repositories/dadata-repository.factory';

const dadataRepository = createDadataRepository();
const loadingOptions = async (
  inputValue: string,
  onlyCity?: boolean,
  allowedTypes: DadataAreaType[] = [DadataAreaType.CITY]
): Promise<DadataSelectOption[]> => {
  const areas = await dadataRepository.getSuggestedAreas(inputValue, {
    onlyCity,
    types: allowedTypes,
  });
  return areas.map((area) => ({
    label: area.value,
    value: area,
  }));
};

export type DadataSelectOption = {
  label: string | number | JSX.Element;
  value: IDadataArea | null | undefined;
};

export type DadataAutocompleteProps =
  Omit<AsyncProps<DadataSelectOption, boolean, GroupBase<DadataSelectOption>>, 'loadOptions'> &
  {
    onlyCity?: boolean;
    allowedTypes?: DadataAreaType[];
  };

export const DadataAutocomplete = (props: DadataAutocompleteProps) => {
  const { onlyCity, allowedTypes = [DadataAreaType.CITY], ...rest } = props;
  return (
    <AsyncSelect
      {...rest}
      loadOptions={(value) => loadingOptions(value, onlyCity, allowedTypes)}
    />
  );
};
