import * as yup from "yup";

import React, { useCallback } from "react";

import { AppDispatch } from "../../../../store/store";
import BorderedInput from "../../../teams/ui/components/bordered-input";
import { Formik } from "formik";
import { Player } from "../../domain/interfaces/player";
import { TeamPlayerForm } from "../../../teams/ui/components/team-card/styles";
import UDButton from "../../../ud-ui/components/button";
import UDFormDropZone from "../../../ud-form/components/dropzone";
import UDFormInput from "../../../ud-form/components/input";
import { birthDateFormatter } from "../../../ud-form/components/input/formatters/birthDateFormatter";
import { upsertTournamentUser } from "../../store/actions";
import { useDispatch } from "react-redux";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import useTournamentUsers from "../hooks/useTournamentUsers";

interface IProps {
  onSubmit: (player: Player) => void
  initialValues: {}
}

function CreatePlayerForm(props: IProps) {
  const { onSubmit, initialValues } = props
  const dispatch = useDispatch<AppDispatch>()
  const { isProcessing } = useTournamentUsers()
  const { info } = useOrganizer()

  const handleValidator = yup.object().shape({
    name: yup.string().required('Обязательное поле'),
    surname: yup.string().required('Обязательное поле'),
    birthDate: yup
      .string()
      .matches(
        /^(\d{2})\.(\d{2})\.(\d{4})$/,
        'Дата рождения должна быть в формате: число.месяц.год',
      )
      .required('Обязательное поле'),
  });

  const handleSubmit = useCallback((values: { [p: string]: string }) => {
    if (!info) return

    const photo = values.photo as any as File[] | undefined

    dispatch(upsertTournamentUser({
      tournamentId: info.id,
      name: values.name,
      surname: values.surname,
      middleName: values.middleName,
      birthDate: values.birthDate,
      photo,
    })).then((data) => {
      onSubmit(data.payload as Player)
    })
  }, [dispatch, info]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={handleValidator}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form
            className='d-flex flex-column justify-content-between h-100'
            onSubmit={formProps.handleSubmit}
          >
            <TeamPlayerForm>
              <div className='row'>
                <div className='col-6' style={{ paddingRight: 24 }}>
                  <UDFormInput
                    name='surname'
                    label='Фамилия'
                    placeholder='Введите фамилию'
                  />

                  <div className='player-data'>
                    <UDFormInput
                      name='name'
                      label='Имя'
                      placeholder='Введите имя'
                    />

                    <UDFormInput
                      name='middleName'
                      label='Отчество'
                      placeholder='Введите отчество'
                    />
                  </div>

                  <div className='player-data'>
                    <UDFormInput
                      name='birthDate'
                      label='Дата рождения'
                      placeholder='Укажите дату рождения'
                      mask='дд.мм.гггг'
                      formatter={birthDateFormatter}
                      inputComponent={() => <BorderedInput />}
                    />
                  </div>

                  <div className='player-data'>
                    <UDFormInput
                      name='height'
                      label='Рост игрока'
                      placeholder='Укажите рост'
                    />

                    <UDFormInput
                      name='weight'
                      label='Вес игрока'
                      placeholder='Укажите вес'
                    />
                  </div>
                </div>

                <div className='col-6' style={{ paddingLeft: 24 }}>
                  <UDFormDropZone
                    name='photo'
                    label='Фото игрока'
                    value={['']}
                    isCover={false}
                    formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
                  />
                </div>
              </div>

              <div className='form-buttons'>
                <UDButton
                  type='button'
                  variant='secondary'
                  disabled={isProcessing}
                  className='mr-4'
                >
                  Отменить
                </UDButton>

                <UDButton
                  type='submit'
                  variant='primary'
                  disabled={isProcessing}
                  loading={isProcessing}
                >
                  Сохранить
                </UDButton>
              </div>
            </TeamPlayerForm>
          </form>
        )}
      </Formik>
    </>
  );
}

export default CreatePlayerForm;
