import * as yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import { usePlayers } from 'modules/organizer/ui/hooks/usePlayers';
import { tourneysCommonStoreImagesSelector } from 'modules/tourneys/store/common/selectors';
import UDFormDropZone from 'modules/ud-form/components/dropzone/index';
import UDFormInput from 'modules/ud-form/components/input';
import { birthDateFormatter } from 'modules/ud-form/components/input/formatters/birthDateFormatter';
import UDButton from 'modules/ud-ui/components/button';
import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { AppDispatch } from '../../../../../../store/store';
import { Pages } from "../../../../../navigation/domain/enums/pages";
import { fetchTournamentUser } from "../../../../../players/store/actions";
import { TournamentUser } from '../../../../domain/interfaces/TournamentUser';
import { upsertTournamentPlayerApplication } from '../../../../store/players/actions';
import BorderedInput from '../../bordered-input';
import { TeamPlayerForm } from '../../team-card/styles';

type UpsertTeamFormProps = {
  tournamentId: number;
  teamId: number;
  state?: any
};

const UpsertPlayerForm = (props: UpsertTeamFormProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { tournamentId, teamId, state } = props;
  const { isLoading } = usePlayers();
  const navigate = useNavigate()

  const handleDisableForm = useCallback(() => {
    navigate(Pages.TEAMS.builders.edit(teamId, 'players'), { state })
  }, [navigate, state, teamId])

  const handleSubmit = useCallback(
    (values: any) => {
      dispatch(
        upsertTournamentPlayerApplication({
          teamId,
          tournamentId,
          name: values.name,
          surname: values.surname,
          middleName: values.middleName,
          birthDate: values.birthDate,
          height: +values.height,
          weight: +values.weight,
          photo: values.photo,
          // position: values.position.value,
        }),
      ).then((data) => {
        handleDisableForm()
        dispatch(fetchTournamentUser({ userId: (data?.payload as TournamentUser)?.id as number }));
      });
    },
    [dispatch, teamId, tournamentId],
  );
  // const positions = ['Вратарь', 'Защитник', 'Полузащитник', 'Нападающий'];
  // const positionsEng = ['goalkeeper', 'defender', 'midfielder', 'forward'];

  const teamPlayerValidator = yup.object().shape({
    name: yup.string().required('Обязательное поле'),
    surname: yup.string().required('Обязательное поле'),
    birthDate: yup
      .string()
      .matches(
        /^(\d{2})\.(\d{2})\.(\d{4})$/,
        'Дата рождения должна быть в формате: число.месяц.год',
      )
      .required('Обязательное поле'),
  });

  const initialValues = {
    surname: '',
    name: '',
    middleName: '',
    birthDate: '',
    height: '',
    weight: '',
  };

  const images = useSelector(tourneysCommonStoreImagesSelector);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={teamPlayerValidator}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <form
          className='d-flex flex-column justify-content-between h-100'
          onSubmit={formProps.handleSubmit}
        >
          <TeamPlayerForm>
            <div className='row'>
              <div className='player-form col-6'>
                <UDFormInput
                  name='surname'
                  label='Фамилия'
                  placeholder='Введите фамилию'
                />

                <div className='player-data'>
                  <UDFormInput
                    name='name'
                    label='Имя'
                    placeholder='Введите имя'
                  />

                  <UDFormInput
                    name='middleName'
                    label='Отчество'
                    placeholder='Введите отчество'
                  />
                </div>

                <div className='player-data'>
                  <UDFormInput
                    name='birthDate'
                    label='Дата рождения'
                    placeholder='Укажите дату рождения'
                    mask='дд.мм.гггг'
                    formatter={birthDateFormatter}
                    inputComponent={(props) => <BorderedInput {...props} />}
                  />

                  {/* <UDFormSelect
                    name="position"
                    label="Амплуа"
                    placeholder="Укажите амплуа"
                    options={typeOptions}
                  /> */}
                </div>

                <div className='player-data'>
                  <UDFormInput
                    name='height'
                    label='Рост игрока'
                    placeholder='Укажите рост'
                  />

                  <UDFormInput
                    name='weight'
                    label='Вес игрока'
                    placeholder='Укажите вес'
                  />
                </div>
              </div>

              <div className='player-form col-6'>
                <UDFormDropZone
                  name='photo'
                  label='Фото игрока'
                  value={images}
                  isCover={false}
                  formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
                />
              </div>
            </div>

            <div className='form-buttons'>
              <UDButton
                type='button'
                variant='secondary'
                disabled={isLoading}
                onClick={() => {
                  handleDisableForm();
                }}
                className='mr-4'
              >
                Отменить
              </UDButton>

              <UDButton
                type='submit'
                variant='primary'
                disabled={isLoading}
                loading={isLoading}
              >
                Сохранить
              </UDButton>
            </div>
          </TeamPlayerForm>
        </form>
      )}
    </Formik>
  );
};

export default UpsertPlayerForm;
